.l-ticketVertical {
  padding-top: 100px;
  padding-bottom: 100px;
}

.ticketVertical {
  padding: 10px;
  border: 1px solid rgba($color: $dark, $alpha: 0.3);
  border-radius: 16px;
  position: relative;
  background-color: #fff;

  &-sectitle {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
  }

  &-wrapper {
    background: $section-bg;
    border-radius: 16px;
    text-align: center;
    height: 470px;
  }

  &-details {
    padding: 25px 15px;
  }

  &-title {
    font-size: 24px;
    font-weight: bold;
    color: $dark;
    margin-bottom: 12px;

    @include bp(sm) {
      margin-bottom: 28px;
    }
  }

  &-label {
    font-size: 14px;
    color: rgba($dark, 0.2);
    margin-bottom: 5px;

    @include bp(sm) {
      margin-bottom: 15px;
    }
  }

  .myBtn {
    color: $primary;
    font-weight: bold;
  }

  &-price {
    font-size: 14px;
    color: rgba($dark, 0.2);
    margin-bottom: 0;

    .num {
      font-size: 32px;
      font-weight: bold;
      color: $primary;
    }
  }

  &-divider {
    position: relative;

    &::after,
    &::before {
      position: absolute;
      content: "";
      display: block;
      border-radius: 50%;
      background: $light;
      width: 21px;
      height: 39px;
      top: -19px;
    }

    &::after {
      #{$left}: -10px;
    }

    &::before {
      #{$right}: -10px;
    }
  }

  &-date {
    position: relative;

    .form-control {
      background-color: white;
      color: $dark;
      font-size: 16px;
      font-weight: bold;
      line-height: 28px;
      text-align: center;
      border-radius: 16px;

      &:disabled {
        background-color: #e9ecef;
      }
    }

    .dropdown-menu {
      direction: initial;
      overflow: hidden;
      border-radius: 16px;
      border-radius: 16px;
      box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.2);

      .ngb-dp-months {
        justify-content: center;
      }

      .ngb-dp-header {
        padding-top: 17px;
        padding-bottom: 15px;
      }
    }
  }

  &-dateToggle {
    position: absolute;
    padding: 0;
    #{$left}: 12px;
    color: $gray;
    top: 17px;
    font-size: 21px;

    @include bp(sm) {
      #{$left}: 33px;
    }

    &:focus {
      box-shadow: none;
    }
  }

  &-dateicon {
  }

  &-choosedate {
  }

  &-counter {
    padding: 20px 30px;
    background: $light;
    border: 1px solid rgba($color: $dark, $alpha: 0.3);
    border-radius: 16px;
    display: flex;
    align-items: center;
  }

  &-plus,
  &-minus {
    font-size: 20px;
    font-family: "Font Awesome 5 Free";
    padding: 0;

    &:focus {
      box-shadow: none;
    }
  }

  &-plus {
    color: $success;
    float: #{$left};
  }

  &-count {
    font-size: 16px;
    font-weight: bold;
    flex: 1;
  }

  &-minus {
    color: $danger;
    float: #{$right};
  }
}
