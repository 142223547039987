.l-homePage {
  position: relative;
  @include gradient-y(#fafafb, #fff, 0, 40%);

  &::before {
    position: absolute;
    content: "";
    top: 0;
    background: image("home/hero-bg-lines.webp");
    #{$right}: 0;
    width: 100%;
    height: 500px;
    position: absolute;
    z-index: 0;
  }

  &-events {
    position: relative;
    z-index: 1;
    padding-top: 20px;
    padding-bottom: 20px;

    @include bp(md) {
      padding-bottom: 80px;
      padding-top: 60px;
    }

    &::before {
      content: image("home/contact-left-icon.png");
      bottom: -20px;
      #{$right}: 0;
      position: absolute;
      z-index: 0;
    }
  }

  &-statistics {
    position: relative;
    z-index: 1;
    padding-top: 80px;
    padding-bottom: 100px;

    &::after {
      content: image("home/apps-right-icon.png");
      bottom: 30px;
      #{$left}: 0;
      position: absolute;
      z-index: 0;
    }
  }

  &-packages {
    position: relative;
    z-index: 1;
    text-align: center;
    background-color: $section-bg;
    padding-top: 100px;
    padding-bottom: 100px;
    overflow: hidden;

    &::before {
      content: "";
      background: image("home/packages-left-icon.png") no-repeat top/contain;
      position: absolute;
      width: 300px;
      height: 300px;
      top: 25px;
      #{$right}: -150px;
      z-index: 0;

      @include bp(sm) {
        width: 600px;
        height: 600px;
        top: 50px;
        #{$right}: -400px;
      }

      @include bp(md) {
        width: 900px;
        height: 900px;
        top: 50px;
        #{$right}: -600px;
      }
    }

    &::after {
      content: "";
      background: image("home/packages-right-icon.png") no-repeat top/contain;
      position: absolute;
      width: 300px;
      height: 300px;
      bottom: -150px;
      #{$left}: -150px;
      z-index: 0;

      @include bp(sm) {
        width: 600px;
        height: 600px;
        bottom: -300px;
        #{$left}: -400px;
      }

      @include bp(md) {
        width: 900px;
        height: 900px;
        bottom: -450px;
        #{$left}: -600px;
      }
    }
  }
}
