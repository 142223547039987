.admin {
  padding: 30px 20px;
  @include bp(md) {
    padding: 30px 45px;
  }
  &-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 1.9rem;
    .titleHead {
      font-size: 24px;
      font-weight: bold;
    }
  }
  &-types {
    margin-bottom: 1.9rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 30px;
    .typeBox {
      height: 120px;
      padding: 30px 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background: #fff;
      border-radius: 16px;
      @extend .shadow;
      &-icon {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }
      &-sum {
        margin-#{$left}: 15px;
        .text {
          color: $gray;
          margin-bottom: 0.2rem;
        }
        .number {
          font-weight: bold;
        }
      }
      &:first-child .typeBox {
        &-icon {
          background: rgba($primary, 0.2);
          color: $primary;
        }
        &-sum .number {
          color: $primary;
        }
      }
      &:nth-child(2) .typeBox {
        &-icon {
          background: rgba($warning, 0.2);
          color: $warning;
        }
        &-sum .number {
          color: $warning;
        }
      }
      &:nth-child(3) .typeBox {
        &-icon {
          background: rgba($danger, 0.2);
          color: $danger;
        }
        &-sum .number {
          color: $danger;
        }
      }
      &:nth-child(4) .typeBox {
        &-icon {
          background: rgba($info, 0.2);
          color: $info;
        }
        &-sum .number {
          color: $info;
        }
      }
      &:last-child .typeBox {
        &-icon {
          background: rgba($secondary, 0.2);
          color: $secondary;
        }
        &-sum .number {
          color: $secondary;
        }
      }
    }
  }
  &-content {
    background: $light;
    border-radius: 16px;
    @extend .shadow;
    padding: 30px;
    .form-group {
      button.d-block {
        @extend .shadow;
      }
      input {
        background-color: rgba($gray, 0.1);
        border: currentColor;
      }
      button {
        font-weight: bold;
      }
    }
    .btn {
      padding: 1rem 1.5rem;
    }
    .contentInfo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 40px;
      padding: 10px;
      &-name {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 1rem;
        @include bp(sm) {
          width: 60%;
        }
        @include bp(md) {
          width: 50%;
          margin-bottom: 0;
        }
        @include bp(lg) {
          width: 70%;
        }
        .arrowIcon,
        .contentIcon {
          background: rgba($gray, 0.2);
          border-radius: 50%;
          text-align: center;
          color: $dark;
        }
        .arrowIcon {
          width: 24px;
          height: 24px;
          line-height: 24px;
          font-size: 10px;
        }
        .contentIcon {
          width: 60px;
          height: 60px;
          line-height: 60px;
          margin-inline-start: 17px;
          margin-inline-end: 17px;
        }
        .contentName {
          font-size: 26px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 47px;
        }
      }
      &-data {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        @include bp(sm) {
          width: 60%;
        }
        @include bp(md) {
          width: 50%;
        }
        @include bp(lg) {
          width: 30%;
        }
      }
    }
  }

  &-question {
    background: $light;
    border-radius: 16px;
    @extend .shadow;
    padding: 30px;
    @include bp(sm) {
      padding: 44px 34px;
    }
  }
  &-textContainer {
    position: relative;

    button {
      position: absolute;
      top: 36px;
      left: 24px;
      width: 70px;
      height: 52px;
      line-height: 12px;
      z-index: 10;
    }
    textarea {
      background: rgba($gray, 0.2);
      border-radius: 16px;
      @extend .shadow;
      padding: 30px;
      @include bp(sm) {
        padding: 44px 34px;
      }
      &:focus {
        background: rgba($gray, 0.5);
      }
      &::placeholder {
        color: $dark;
      }
      position: relative;
      border: none;
      // height: 95px;
      resize: none;
      outline: none; /* add this to stop highlight on focus */
    }
  }
  &.contact {
    .inpSearch {
      &-input {
        width: 100%;
        @include bp(lg) {
          width: 50%;
        }
      }
    }
    .table {
      tr.active {
        background: rgba($warning, 0.2);
        border-color: $warning;
        td {
          background: inherit;
        }
      }
    }
  }
  &.events {
    .admin-types {
      .typeBox {
        &.isPrimary {
          .typeBox-icon {
            background: rgba($primary, 0.2);
            color: $primary;
          }
          .typeBox-sum {
            .number {
              color: $primary;
            }
          }
        }
        &.isWarning {
          .typeBox-icon {
            background: rgba($warning, 0.2);
            color: $warning;
          }
          .typeBox-sum {
            .number {
              color: $warning;
            }
          }
        }
        &.isDanger {
          .typeBox-icon {
            background: rgba($danger, 0.2);
            color: $danger;
          }
          .typeBox-sum {
            .number {
              color: $danger;
            }
          }
        }
        &.isGray {
          .typeBox-icon {
            background: rgba($gray, 0.2);
            color: $gray;
          }
          .typeBox-sum {
            .number {
              color: $gray;
            }
          }
        }
      }
    }
  }
}
