.l-eventsPage {
  position: relative;
  background: image("home/blue-dots.png") no-repeat #{$left} top / auto;
  padding: 30px 15px 50px;

  @include bp(md) {
    padding: 50px 45px 100px;
  }
}

.l-events {
  padding-top: 100px;
  padding-bottom: 100px;
}

.myEvents {
  padding: 30px 45px;

  &-sectitle {
    font-size: 24px;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
  }

  &-actions {
    // margin: 50px 0;
    // display: flex;
    // justify-content: center;
  }

  .eventsActions {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 4rem;
    flex-wrap: wrap;

    @include bp(md) {
      justify-content: center;
    }

    &-search {
      width: 100%;

      margin-bottom: 15px;

      @include bp(sm) {
        width: 310px;
        margin-bottom: 15px;
      }

      @include bp(md) {
        width: 450px;
        margin-bottom: 0px;
      }
    }

    &-status {
      button {
        border-radius: 16px;
      }
    }

    &-type {
      width: 100%;
      margin-#{$left}: 0;
      margin-bottom: 15px;

      @include bp(sm) {
        width: 160px;
        margin-#{$left}: 20px;
        margin-bottom: 0;
      }
    }

    &-place {
      width: 100%;
      margin-#{$left}: 0;
      margin-bottom: 15px;

      @include bp(sm) {
        width: 190px;
        margin-#{$left}: 20px;
        margin-bottom: 0;
      }
    }

    &-date {
      width: 105px;
      margin-#{$left}: 0;

      @include bp(md) {
        margin-#{$left}: 20px;
      }

      &-gridicon,
      &-calendar {
        padding: 18px;
        background: $primary;
        color: $light;

        &.is-active {
          color: $dark;
          background: $light;
          box-shadow: 0 2px 8px 0 $gray;
        }
      }

      &-gridicon {
        border-radius: 0 16px 16px 0;
      }

      &-calendar {
        border-radius: 16px 0 0 16px;
      }
    }
  }

  &-search {
    border: 1px solid $gray;
    border-radius: 15px;
    font-size: 20px;
    margin-#{$right}: 20px;
    padding: 5px;

    input {
      border: none;
    }

    button {
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }

  &-location {
    border: none;
    color: $dark;
    font-size: 14px;
    font-weight: bold;
  }

  &-date {
    // display: flex;
    // justify-content: space-between;
    border-radius: 8px;
    font-size: 16px;
  }
}
