.header {
  position: relative;
  box-shadow: 0 4px 8px 0 rgba(15, 40, 55, 0.1);

  &-navbar {
    padding-top: 10px;
    padding-bottom: 10px;

    // .navbar-brand {
    //   width: 70px;
    // }

    .header-menu {
      margin: 10px 0;
      @include bp(md) {
        margin: 0;
      }
    }
    @include bp(md) {
      padding-bottom: 0;
    }
  }
  &-togglerBtn {
    padding: 0;
    &:focus {
      box-shadow: none;
      outline: 0;
    }
  }

  &-menu {
    .nav-item {
      border-bottom: 0;

      .nav-link {
        font-size: 14px;
        line-height: 28px;
        color: $gray;
        border-bottom: 2px solid transparent;
        @include trans;
        border-radius: 0;
        padding: 10px 0;

        @include bp(xl) {
          font-size: 16px;
          padding: 20px 18px;
        }
        @include bp(md) {
          padding: 10px;
        }
      }

      &.active .nav-link,
      .nav-link:hover,
      .nav-link:focus {
        color: $primary;
        @include bp(md) {
          border-bottom-color: $primary;
        }
      }

      &.dropdown {
        margin-bottom: 8px;

        @include bp(md) {
          margin-bottom: 0;
        }
      }
    }
  }

  &-additonLinks {
    display: flex;

    .dropdown {
      margin: 0 10px;
      flex: 1;
      @include bp(md) {
        flex: initial;
        margin: 0;
      }
    }

    @include bp(lg) {
      margin-bottom: 0;
    }

    @include bp(md) {
      flex-direction: row;
      height: 42px;
    }
  }

  &-search {
    position: relative;
    margin-bottom: 8px;

    @include bp(md) {
      margin-bottom: 0;
      margin-#{$right}: 12px;
    }

    @include bp(xl) {
      margin-#{$right}: 20px;
    }

    &-icon {
      position: absolute;
      top: 13px;
      #{$left}: 20px;
      color: $gray;
    }

    .form-control {
      width: 286px;
      height: 43px;
      padding-top: 11px;
      padding-bottom: 11px;
      padding-#{$left}: 50px;
      border-radius: 16px;
      background-color: rgba($color: $gray, $alpha: 0.1);
      width: 100%;

      @include bp(md) {
        width: auto;
      }

      &:not(:focus) {
        border-color: rgba($color: $gray, $alpha: 0.1);
      }
    }
  }

  .dropdown .header-loginBtn {
    padding: 10px 16px;
    width: 100%;

    @include bp(md) {
      width: auto;
    }
  }

  &-loginBtn {
    position: relative;
    color: $dark;
    font-size: 14px;
    line-height: 20px;
    padding: 12px 16px;
    border: 1px solid rgba($color: $gray, $alpha: 0.5);
    border-radius: 16px;
    margin-bottom: 8px;

    @include bp(md) {
      margin-bottom: 0;
      margin-#{$right}: 12px;
    }

    @include bp(xl) {
      margin-#{$right}: 20px;
    }
  }

  &-langBtn {
    @apply flex items-center space-x-.5 rtl:space-x-reverse;
    padding: 0;
    color: rgba($color: $dark, $alpha: 0.5);
    font-size: 14px;
    font-weight: bold;
    line-height: 25px;
    margin-bottom: 8px;
    transition: color 0.3s;

    @include bp(md) {
      margin-bottom: 0;
    }

    &:hover {
      color: rgba($color: $dark, $alpha: 1);
    }
    &:focus {
      box-shadow: none;
    }
  }

  .dropdown-menu {
    .dropdown-item {
      padding: 12px 15px;
    }
  }
}
