/*!
 * Elbaz Branding v4.1.1   (::)   English language
 * Copyright 2019 Elbaz Front-end Team  
 * Licensed under MIT (https://Elbaz.sa/)
 * LinkedIn:: in/elbazz :: Mail 
 */
//-----------------------------------------------  

// =====> main sass key
$mainSiteLang : ar;
// active the next variable if you want arabic direction
//$mainSiteLang : ar; 

//-----------------------------------------------  
// =====> main sass partial style 
@import "../../scss/main";