// myTickets
.invoices-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(365px, 1fr));
  gap: 30px;

  @media screen and (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
.ticketWithWrapper {
  background-color: $light;
  border-radius: 16px;
  @extend .shadow;
  margin-bottom: 30px;
  @include bp(sm) {
    padding: 30px 12px;
  }
  padding: 30px 32px;
  &-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin-bottom: 1.5rem;
    @include bp(sm) {
      height: 100%;

      flex-direction: row;
    }
    @include bp(lg) {
      margin-bottom: 0;
    }

    .imgWrapper {
      margin-bottom: 0.5rem;
      @include bp(sm) {
        margin-bottom: 0;
      }
      .image {
        width: 160px;
        height: 125px;
        border-radius: 16px;
      }
    }
    .contentWrapper {
      margin-#{$left}: 30px;
    }
  }
  &-right {
    position: relative;
    background-color: $gray-100;
    overflow: hidden;
    &::after,
    &::before {
      content: "";
      position: absolute;
      left: 40%;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: $white;
    }
    &::after {
      top: -20px;
    }
    &::before {
      bottom: -20px;
    }
    .ticketQtcode {
      width: 150px;
    }
  }
  .horizontalTicketCard {
    margin-bottom: 0;
  }
}

.myTickets {
  .card {
    @extend .shadow;
    overflow: visible;
    background-color: white;
    height: fit-content;
    border-radius: 16px !important;
  }
  .card-header {
    padding: 0;
    margin: 0;
    border: 0;
    background-color: transparent;
  }

  .custom-header {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: left;
    outline: 0;
    padding: 15px;

    .details {
      width: 100%;
    }
    .price {
      span {
        font-weight: 700;
        color: $primary;
        margin-inline-end: 15px;
      }
      margin: 20px 0 0;

      @include bp(sm) {
        margin-inline-start: auto;
        margin-top: 0;
      }
    }

    p {
      margin: 0;
    }

    &:not(.collapsed) {
    }

    .event-image {
      margin: -15px -15px 15px;
      width: calc(100% + 30px);
      border-radius: 16px 16px 0 0;
      overflow: hidden;

      .skeleton-box {
        border-radius: 0;
      }
    }

    @include bp(sm) {
      flex-direction: row;
    }

    [dir="rtl"] & {
      text-align: right;
    }
  }
}
