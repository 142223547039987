// my events
.eventsCards {
  position: relative;
  border: 1px solid $section-bg;
  background-color: white;
  border-radius: 8px;
  margin-bottom: 30px;
  box-shadow: 0 4px 8px 0 rgba(15, 40, 55, 0.1);
  @include trans(box-shadow);

  &:hover {
    cursor: pointer;
  }

  &-cover {
    // height: 200px;
    @include br-t(8px);
    overflow: hidden;

    .image {
      transform-origin: 50%;
      @include trans();
    }
  }

  &-body {
    padding: 20px;

    @include bp(md) {
    }
  }

  &-cardTitle {
    font-size: 16px;
    color: $dark;
    font-weight: bold;
    line-height: 28px;
    margin-bottom: 10px;
  }

  &-cardDate {
    font-size: 12px;
    color: $txt3;
    margin-bottom: 18px;
  }

  &-location,
  &-type {
    border-radius: 30px;
    font-size: 12px;
    line-height: 24px;
    padding: 6px 14px;
    display: inline-block;
  }

  &-location {
    background-color: rgba($color: $primary, $alpha: 0.1);
    color: $primary;
    margin-#{$right}: 10px;
  }

  &-type {
    color: rgba($color: $dark, $alpha: 0.5);
    background-color: rgba($color: $gray, $alpha: 0.1);
  }

  &-footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px 5px 10px;
    color: $txt3;

    &-dropDown {
      .dropdown-toggle {
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        transition: background-color 0.3s;

        &:hover {
          background-color: rgba($color: #000000, $alpha: 0.1);
        }
      }

      .dropdown-menu {
        width: 160px;
        height: fit-content;
        border-radius: 16px;
        padding: 13px 11px;

        .dropdown-item {
          padding: 0;
          margin-bottom: 15px;

          &:last-child {
            margin-bottom: 0;
          }

          &:active,
          &:hover {
            color: $dark;
            background-color: $light;
          }

          i {
            color: $primary;
            margin-#{$right}: 12px;
          }
        }
      }
    }

    [dir="ltr"] & {
      padding: 5px 10px 5px 20px;
    }
  }
}

.events {
  &-types {
    margin-bottom: 1.9rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 30px;

    .typeBox {
      height: 120px;
      padding: 30px 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background: #fff;
      border-radius: 16px;
      @extend .shadow;

      &-icon {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }

      &-sum {
        margin-#{$left}: 15px;

        .text {
          color: $gray;
          margin-bottom: 0.2rem;
        }

        .number {
          font-weight: bold;
        }
      }

      &:first-child .typeBox {
        &-icon {
          background: rgba($warning, 0.2);
          color: $warning;
        }

        &-sum .number {
          color: $warning;
        }
      }

      &:nth-child(2) .typeBox {
        &-icon {
          background: rgba($primary, 0.2);
          color: $primary;
        }

        &-sum .number {
          color: $primary;
        }
      }

      &:nth-child(3) .typeBox {
        &-icon {
          background: rgba($secondary, 0.2);
          color: $secondary;
        }

        &-sum .number {
          color: $secondary;
        }
      }

      &:nth-child(4),
      &:nth-child(5) {
        .typeBox {
          &-icon {
            background: rgba($danger, 0.2);
            color: $danger;
          }

          &-sum .number {
            color: $danger;
          }
        }
      }
    }
  }
}
