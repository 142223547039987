@mixin border-rad($radius: $border-radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
}

// Single side border-radius
@mixin border-top-rad($radius) {
  border-top-left-radius: $radius;
  border-top-right-radius: $radius;
}

@mixin border-right-rad($radius) {
  border-top-#{$right}-radius: $radius;
  border-bottom-#{$right}-radius: $radius;
}

@mixin border-bottom-rad($radius) {
  border-bottom-right-radius: $radius;
  border-bottom-left-radius: $radius;
}

@mixin border-left-rad($radius) {
  border-top-#{$left}-radius: $radius;
  border-bottom-#{$left}-radius: $radius;
}