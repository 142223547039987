.overviewCalendar {
  min-height: 371px;
  margin-bottom: 30px;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    &-title {
      font-size: 24px;
      color: $primary;
      font-weight: bold;
      line-height: 43px;
    }
  }

  &-colors {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .dateColors {
      position: relative;
      margin-#{$left}: 32px;
      color: $dark;
      font-size: 14px;

      &::after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        top: 5px;
        #{$left}: -15px;
        border-radius: 50%;
      }

      &:nth-child(1):after {
        background-color: $success;
      }

      &:nth-child(2):after {
        background-color: $warning;
      }

      &:nth-child(3):after {
        background-color: $danger;
      }

    }

  }

  &-body {
    margin-bottom: 20px;
  }

  //  ==============================

  .cal-month-view {
    background-color: transparent;

    .cal-day-number {
      display: block;
      float: inherit;
      margin-left: inherit;
      margin-right: inherit;
      text-align: center;
    }

    .cal-cell-row {
      border: 0;

      &:hover {
        background-color: transparent;
      }

      .cal-cell {
        border-radius: 16px;
      }
    }

    .cal-days {
      border: 0;
    }

    .cal-cell-row .cal-cell:hover,
    .cal-cell.cal-has-events.cal-open {
      background-color: $danger;

      .cal-day-number {
        color: $white;
      }
    }

    .cal-day-cell {
      min-height: auto;

      &.cal-today {
        border-radius: 16px;
        background-color: #FFFFFF;
        text-align: center;

        .cal-day-number {
          font-size: inherit;
          color: #3DBEB4;
        }
      }

      &:not(:last-child) {
        border: 0;
      }
    }

    .cal-cell-top {
      min-height: auto;
    }

  }
}
