.breadcrumb {
  background-color: transparent;
  margin-bottom: 15px;

  &-item {
    a {
      color: $gray;
    }

    &.active {
      color: $dark;
    }
  }

  .breadcrumb-item+.breadcrumb-item::before {
    content: '\f053 ';
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
  }

  .breadcrumb-item {
    a {
      img {
        display: inline-block;
      }
    }
  }
}
