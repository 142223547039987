.event-calendar {
  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 30px;

    .controls {
      display: flex;
      align-items: center;
      h3 {
        margin: 0 30px;
        font-size: 21px;
        font-weight: 700;
        min-width: 160px;
        text-align: center;
      }

      .btn {
        padding: 0;
        width: 40px;
        height: 40px;
        border: 1px solid #ddd;
        background-color: white;
        border-radius: 50%;
        font-family: initial;

        &:hover {
          background-color: $primary;
          border-color: $primary;
          color: white;
        }
      }
    }
  }

  &__body {
    background-color: white;
    padding: 15px;
    border-radius: 8px;
  }

  .cal-day-badge,
  .cal-event {
    display: none;
  }

  .cal-has-events {
    // pointer-events: none;
    // background-color: $primary !important;
    // color: white;
    cursor: default !important;
    .cal-day-number {
      opacity: 1;
    }
  }
}

.flatpickr-months .flatpickr-month {
  height: 40px;
}

.has-times {
  padding: 10px 10px 50px;
  width: 100%;
}

.calday {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  > div {
    display: flex;
    align-items: center;
    font-size: 12px;
    background-color: transparent !important;
    box-shadow: none;
    border-radius: 0% !important;
    border: none;
    font-size: 10px;
    margin-bottom: 0px;
    padding: 5px 5px;
    width: 100%;
  }
  .btn {
    padding: 0;
    width: 20px;
    height: 20px;
    background-color: #eee;
    border-radius: 50%;
    margin-inline-start: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 9px;

    &:hover {
      background-color: $primary;
      color: white;
    }
  }
}

.cal-day-times {
  display: flex;
  flex-wrap: wrap;
  > div {
    display: flex;
    align-items: center;
    font-size: 12px;
    background-color: white;
    box-shadow: 1px 2px 4px rgba($color: #000000, $alpha: 0.1);
    padding: 5px 10px;
    border-radius: 8px;
    width: 100%;
    margin-bottom: 10px;
    border: 1px solid #eee;
    .btn {
      padding: 0;
      width: 20px;
      height: 20px;
      background-color: #eee;
      border-radius: 50%;
      margin-inline-start: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 9px;

      &:hover {
        background-color: $primary;
        color: white;
      }
    }
  }

  &.all-day {
    padding: 10px;

    > div {
      margin: 0;
      // height: 100%;
      color: #071927;
      justify-content: center;
      font-size: 14px;
    }
  }
}

.fc-event-time,
.fc-daygrid-event-dot {
  display: none;
}

.fc-day {
  &.disabled {
    background-color: #eee;
    pointer-events: none;
    color: black;

    .fc-daygrid-day-number {
      opacity: 0.1;
    }
  }
  .add-date {
    font-size: 16px;
    position: absolute;
    bottom: -20%;
    left: 50%;
    transform: translate(-50%, 50%);
    transition: 0.3s;
    cursor: pointer;
    background-color: white;
    padding: 5px 10px;
    box-shadow: 2px 4px 8px rgb(0 0 0 / 10%);
    border-radius: 25px;
    transition: 0.3s;
    opacity: 0;
    visibility: hidden;

    &:hover {
      background-color: $primary;
      color: white !important;
    }

    i {
      font-size: 80%;
    }
  }
  &:hover {
    .add-date {
      bottom: 18px;
      opacity: 1;
      visibility: visible;
    }

    .has-times .add-date {
      bottom: 30px;
      opacity: 1;
      visibility: visible;
    }
  }
}

.activeBtn {
  text-align: center;
  border: 1px solid $primary;
  border-radius: 10px;
  font-weight: 700;
  color: $primary;
  padding: 3%;
  cursor: pointer;
}

.activeBtn:hover {
  background-color: $primary;
  color: white;
}

.inactiveBtn {
  text-align: center;
  border: 1px solid red;
  border-radius: 10px;
  font-weight: 700;
  color: red;
  padding: 3%;
  cursor: pointer;
}

.inactiveBtn:hover {
  background-color: red;
  color: white;
}

.fc-red {
  box-shadow: 1px 2px 4px rgba($color: #000000, $alpha: 0.1);
  padding: 3px;
  border-radius: 4px;
  border: 0;
  border-inline-start: 4px solid red;
  font-weight: normal;
  font-size: 13px;
  display: block !important;
}

.fc-success {
  box-shadow: 1px 2px 4px rgba($color: #000000, $alpha: 0.1);
  padding: 3px;
  border-radius: 4px;
  border: 0;
  border-inline-start: 4px solid $primary;
  font-weight: normal;
  font-size: 13px;
  display: block !important;
}
.fc-h-event,
.fc-event {
  // background-color: white;

  &.fc-event-tickets {
    background: rgba($color: $primary, $alpha: 0.1);
    height: 100px;
    border: 0;
    box-shadow: none;
    padding: 0;
  }

  .fc-event-main {
    color: inherit;
  }
}

.fc-daygrid-dot-event .fc-event-title {
  font-weight: normal;
  font-size: 13px;
}
