.eventdetails {
  &-wrapper {
    padding: 0;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    @include bp(md) {
      flex-direction: row;
    }
  }

  &-cover {
    @include bp(md) {
      flex-grow: 1;

      & + .eventdetails-card {
        flex: 0 0 330px;
        max-width: 330px;
      }
    }

    img {
      width: 100%;
      height: 530px;
      border-radius: 16px;
    }
  }

  &-info {
    flex: initial;
    width: 100%;
    margin-bottom: 30px;
    @include bp(md) {
      margin-inline-end: 30px;
      margin-bottom: 0;
      flex: 1;
    }
  }

  &-card {
    padding: 12px;
    border: 1px solid #f4f5f7;
    border-radius: 16px;
    position: sticky;
    top: 15px;
    box-shadow: 0 4px 8px 0 rgba($color: #000000, $alpha: 0.1);
    min-width: 100%;

    @include bp(md) {
      min-width: 330px;
    }
  }

  &-details {
    background: $section-bg;
    padding: 12px;
    border-radius: 8px;
    height: 100%;
  }
  &-itemDetails {
    place-self: center;
  }

  &-label {
    height: 60px;
    width: 60px;
    border-radius: 16px;
    background-color: $gray;
    color: $light;
    text-align: center;
    padding: 1%;
    font-size: 24px;
    font-weight: bold;
    overflow: hidden;
    margin-bottom: 20px;

    span {
      font-size: 12px;
      margin-top: -10px;
      display: block;
    }
  }

  &-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  &-list {
  }

  &-item {
    display: flex;
    margin-bottom: 20px;

    .text {
      margin: 0;
      padding: 0;
      font-size: 12px;
      padding: 3px 3px 0 0;
    }

    .name {
      margin: 0;
      padding: 0;
      color: $info;
      font-size: 12px;
      padding: 3px 3px 0 0;
    }
  }

  &-statsicon {
    color: $light;
    width: 24px;
    height: 24px;
    border: 1px solid $dark;
    display: inline-block;
    position: relative;
    margin: 10px;
    padding-#{$left}: 7px;

    i {
      font-size: 9px;
    }
  }

  &-divider {
    border: 1px dashed rgba($color: $gray, $alpha: 0.5) !important;

    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      background: $light;
      width: 21px;
      height: 39px;
      position: relative;
      #{$left}: 101%;
      top: -60px;
    }

    &::before {
      content: "";
      display: block;
      border-radius: 50%;
      background: $light;
      width: 21px;
      height: 39px;
      position: relative;
      #{$right}: 24px;
      top: -19px;
    }
  }

  &-ticket {
    margin-top: 30px;
  }

  &-category {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba($gray, 0.1);
    padding-bottom: 5px;
    width: 90%;
    margin: 0 auto 15px;
  }

  &-desc {
    font-size: 14px;
    color: $gray;
    margin: 0;

    span {
      font-weight: bold;
      color: $primary;
    }
  }

  &-select {
    text-align: center;

    select {
      border: none;
      background: transparent;
      margin-bottom: 5px;
    }

    button {
      border-radius: 16px;
      padding: 24px 90px;
    }
  }

  &-content {
    &-desc {
      font-size: 16px;
      color: $gray;
      margin-bottom: 32px;
    }

    &-location {
      padding: 20px 40px;
      border-radius: 30px;
      color: $primary;
      background: rgba($color: $primary, $alpha: 0.1);
      display: inline-block;
      margin-#{$right}: 20px;
    }

    &-EventType {
      padding: 20px 40px;
      border-radius: 30px;
      color: $gray;
      background: rgba($color: $gray, $alpha: 0.1);
      display: inline-block;
    }
  }

  &-pageTitle {
    font-size: 48px;
    font-weight: bold;
    margin-top: 45px;
    margin-bottom: 24px;
  }

  &-terms {
    &-title {
      font-size: 20px;
      font-weight: bold;
      color: $dark;
      margin-bottom: 16px;
    }

    &-list {
      list-style-type: none;
      margin-bottom: 50px;
      color: $gray;
      padding: 0;
      font-size: 16px;
    }
  }

  &-sponsors {
    margin-bottom: 60px;
  }

  &-location {
    &-title {
      font-size: 20px;
      font-weight: bold;
      color: $dark;
      margin-bottom: 16px;
    }

    button {
      font-size: 16px;
      border-radius: 16px;
      display: block;
      margin-top: 16px;
    }
  }

  &-map {
    margin-top: 16px;
    margin-bottom: 60px;
  }

  &-eventCard {
    background: $section-bg;
    padding-top: 70px;
    padding-bottom: 40px;

    &-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 50px;
      flex-direction: column;

      @include bp(md) {
        flex-direction: row;
        align-items: flex-start;
      }
    }

    &-title {
      font-size: 36px;
      font-weight: bold;
      margin-bottom: 20px;

      @include bp(md) {
        margin-bottom: 0;
      }
    }

    &-more {
      padding: 12px 30px;
      font-size: 14px;
      font-weight: bold;
      color: $dark;
      border-radius: 16px;
      background-color: $light;
      box-shadow: 0 2px 5px 0 rgba($dark, 0.05);
    }
  }

  &-setting {
    margin: auto;
    padding: 30px 0 0;

    &-title {
      color: $primary;
      font-size: 16px;
      font-weight: bold;
      line-height: 28px;
      margin-bottom: 30px;
    }

    &-subTitle {
      color: $dark;
      font-size: 16px;
      font-weight: bold;
      line-height: 28px;
      margin-bottom: 20px;
    }

    &-control {
      margin-bottom: 25px;
    }

    &-controlDesc {
      color: $gray;
      font-size: 12px;
      line-height: 21px;
      margin-bottom: 0;
    }

    .custom-control .custom-control-input .custom-control-label {
      font-size: 16px;
      line-height: 28px;
      color: $primary;
    }
  }

  &-actions {
    width: 280px;
    max-width: 100%;
    margin: 0 auto;
  }

  &-seeMoreBtn {
    color: #071927;
    font-size: 12px;
    line-height: 21px;
    text-align: center;
    margin: 0 auto;
    display: block;
    padding: 0;
    margin-bottom: 15px;
  }

  &-bookBtn {
    width: 100%;
  }
}
