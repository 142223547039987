.wizard2 {
  position: relative;
  display: flex;
  flex-direction: column;

  // &:not(.wizard2-vertical) .wizard2-item {
  //   margin-left: auto;
  //   margin-right: auto;
  // }

  &.wizard2-vertical {
    .wizard2-tabs {
      padding: 0;
    }

    .wizard2-items {
      justify-content: space-between;
      flex-direction: column;

      @include bp(md) {
        justify-content: center;
      }
    }

    .wizard2-item {
      margin-bottom: 30px;

      @include bp(md) {
        &:not(:last-child) {
          &:after {
            content: "";
            position: absolute;
            #{$left}: 19.5px;
            top: calc(100% + 5px);
            display: block;
            height: 20px;
            width: 1px;
            z-index: 10;
            background-color: rgba($color: $gray, $alpha: 0.5);
          }
        }
      }
    }
  }

  // =================
  &-title {
    color: $danger;
    font-size: 26px;
    font-weight: bold;
    line-height: 47px;
    margin-bottom: 10px;
  }

  &-desc {
    color: rgba($color: $dark, $alpha: 0.5);
    font-size: 16px;
    line-height: 28px;
  }

  &-tabs {
    padding: 30px;
    font-size: 14px;
    line-height: 23px;

    @include bp(md) {
      padding: 40px 60px;
    }
  }

  &-items {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-right: 0;
    margin-bottom: 0;
    list-style: none;
  }

  &-item {
    position: relative;
    display: flex;
    cursor: pointer;

    &.active {
      pointer-events: none;
      .wizard2-step {
        border-color: $warning;

        .wizard2--check {
          background-color: $warning;
          // display: none;
        }

        .wizard2--icon {
          background-color: $warning;
          display: inline-block;
        }
      }

      .wizard2-name {
        color: $dark;
        font-weight: bold;
      }
    }
    &.disabled {
      pointer-events: none;
      .wizard2-step {
        border-color: rgba($color: $gray, $alpha: 0.5);

        .wizard2--icon {
          background-color: $gray;
          color: $dark;
          display: inline-block;
        }

        .wizard2--check {
          background-color: $gray;
        }
      }

      .wizard2-label {
        color: $gray;
      }

      .wizard2-name {
        color: rgba($color: $dark, $alpha: 0.5);
      }
    }

    > a {
      position: relative;
      display: flex;
      cursor: pointer;

      &.active {
        pointer-events: none;
        .wizard2-step {
          border-color: $warning;

          .wizard2--check {
            background-color: $warning;
            display: none;
          }

          .wizard2--icon {
            background-color: $warning;
            display: inline-block;
          }
        }

        .wizard2-name {
          color: $dark;
          font-weight: bold;
        }
      }
    }

    @media screen and (max-width: 599px) {
      // min-width: 14rem;
      text-align: center;
      margin: 0 0 10px;
      display: block;
    }
  }

  &-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &-label {
    display: block;
    color: $warning;
    font-size: 14px;
    line-height: 25px;
    display: none;

    @include bp(md) {
      display: inline;
    }
  }

  &-items {
    // overflow: scroll;
  }

  &-name {
    color: $primary;
    font-size: 16px;
    line-height: 28px;
    // display: none;

    @include bp(md) {
      display: inline;
    }
  }

  &-step {
    display: inline-block;
    width: 40px;
    height: 40px;
    border: 1px solid $primary;
    padding: 8px;
    border-radius: 50%;
    margin-bottom: 0;
    margin-#{$right}: 15px;

    @at-root .wizard2:not(.wizard2-vertical) .wizard2-step {
      @include bp(md) {
        padding: 10px;
        width: 60px;
        height: 60px;
      }
    }

    .wizard2--icon,
    .wizard2--check {
      width: 22px;
      height: 22px;
      background-color: #0aa66d;
      color: #fff;
      text-align: center;
      line-height: 22px;
      font-size: 10px;
      border-radius: 50%;
      border: 1px solid #000;
      color: #000;
      box-shadow: inset 2px 0px 0 #fff;
      background-color: $primary;

      @at-root .wizard2:not(.wizard2-vertical) {
        .wizard2--icon,
        .wizard2--check {
          @include bp(md) {
            width: 39px;
            height: 39px;
            line-height: 39px;
            font-size: 20px;
          }
        }
      }
    }

    .wizard2--check {
      font-size: 10px;
      border: 1px solid #000;
      display: inline-block;
    }

    // .wizard2--icon {
    //   display: none;
    // }
  }

  // =====================
  &-content {
    padding: 30px;

    &-title {
      color: $primary;
      font-size: 20px;
      font-weight: bold;
      line-height: 36px;
      margin-bottom: 35px;
    }
  }

  &-footer {
    padding: 40px 60px;
    border-top: 1px solid rgba($color: $gray, $alpha: 0.25);
    display: flex;
    justify-content: space-between;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    background-color: $light;
    margin-bottom: 35px;
    flex-direction: column;

    @include bp(md) {
      flex-direction: row;
    }
  }

  &-prevBtn,
  &-nextBtn {
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: 10px;

    @include bp(xs) {
      width: 50%;
    }

    @include bp(sm) {
      width: auto;
    }
  }

  &-prevBtn {
    background-color: $gray;
    color: #fff;
    border-color: $gray;
  }

  &-nextBtn {
  }
}
