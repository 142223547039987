.topRibbon {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px;

  &-info {
    &-title {
      h1 {
        font-size: 1.8rem;
        margin-bottom: 0;
        font-weight: bold;

        @include bp(md) {
          font-size: 2.5rem;
        }
      }
    }
  }

  &-actions {
    display: flex;
    width: 400px;
    flex-direction: column;

    &-group {
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;

      @include bp(md) {
        gap: 10px;
        flex-direction: row;
      }

      .btn {
        &:not(:last-child) {
          margin-bottom: 10px;
        }

        @include bp(md) {
          margin-bottom: 0 !important;
          flex: 0 0 calc(50% - 5px);
          max-width: calc(50% - 5px);
        }
      }
    }

    &-controlWrapper {
      position: relative;


      .form-control {
        padding-#{$right}: 130px;
        border-radius: 16px;
        background-color: rgba($color: $gray, $alpha: .1);
      }

    }

    &-copyBtn {
      position: absolute;
      top: 17px;
      #{$right}: 25px;
      padding: 0;
      color: $primary;
    }

  }



}
