.quickInfo {
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    &-title {
      h2 {
        color: $primary;
      }
    }
    &-actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      // width: 55%;
      .actions-search {
        width: 100%;
        position: relative;
        @include bp(md) {
          width: 50%;
        }
        input {
          outline: none;
          text-indent: 30px;
          width: 100%;
          height: 60px;
          border-radius: 8px;
          border: 1px solid #ececec;
          color: $gray;
          margin-block-start: 10px;
          margin-block-end: 10px;
          &:active {
            border: 1px solid #ececec;
          }
        }
        i {
          position: absolute;
          top: 38%;
          #{$left}: 3%;
          color: $gray;
        }
      }
      button {
        &:nth-child(2) {
          margin-inline-start: 5px;
          margin-inline-end: 5px;
        }
      }
      .btn-light {
        color: $dark !important;
        padding: 15px 9px;
        // width: 45%;
        @media (max-width: 768px) {
          margin-block-end: 5px;
        }
        i {
          color: $primary;
          margin-block-start: 5px;
          margin-block-end: 5px;
        }
      }
    }
  }
  &-details {
    background: #fff;
    border-radius: 16px;
    padding: 30px 10px;
    @include bp(md) {
      padding: 30px;
    }
    @extend .shadow;
    margin-bottom: 1.9rem;
    &-subtitle {
      margin-block-end: 10px;
    }
    &-status {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      @include bp(lg) {
        margin-bottom: 0;
      }
      // margin-inline:15px;
      .icon {
        width: 60px;
        height: 60px;
        border-radius: 50px;
        text-align: center;
        line-height: 65px;
        display: flex;
        align-items: center;
        justify-content: center;
        &.primaryIcon {
          background-color: $primary;
        }
        &.warningIcon {
          background-color: $warning;
        }
        &.dangerIcon {
          background-color: $danger;
        }
        &.successIcon {
          background-color: $success;
        }
        i {
          // background: $primary;
          color: #fff;
          font-size: 22px;
          // box-shadow: 0 0 0 15px $primary;
          // border-radius: 50px;
        }
      }
      .itemStatus {
        margin-#{$left}: 16px;
        p {
          margin: 0;
          color: $gray;
        }
        .openSale {
          background-color: rgba($primary, 0.2);
          color: $primary;
          border-radius: 50px;
          height: 32px;
          line-height: 32px;
          text-align: center;
        }
        .expired {
          background-color: rgba($red, 0.2);
          color: $red;
          border-radius: 50px;
          height: 32px;
          line-height: 32px;
          text-align: center;
        }
      }
    }
  }
  &-dropdownBtn {
    color: $dark;
    font-weight: 700;
    padding: 0;
    width: 100px;
    height: 32px;
    justify-content: flex-start;
    &:after {
      color: $primary;
    }
    &:focus {
      box-shadow: none;
    }
  }
}
