.accountInfo {
  &-verticalNav {
    .nav-pills {
      @extend .shadow;
      width: 350px;
      text-align: center;
      border-radius: 16px;
      // max-height: 550px;
      margin: 30px auto;

      @include bp(md) {
        margin: 0px;
      }

      .nav-link {
        text-align: $left;
        color: $dark;
        margin: 5px 0;

        i {
          margin-inline-end: 15px;
        }

        &.active {
          color: $primary;
          background-color: transparent;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 5px;
            height: 100%;
            background-color: $primary;
          }
        }
      }

      .signOut {
        color: red;

        &.active {
          color: $danger;
        }
      }

      .userImgName {
        margin: 0 auto;
        text-align: center;
        padding: 20px 0;

        .user-img {
          width: 150px;
          height: 150px;
          border-radius: 50%;
          overflow: hidden;
          margin: 0 auto 20px;
          padding: 20px 20px 0;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          background-color: #eee;

          img {
            width: 100%;
            opacity: 0.5;
          }
        }

        .user-name {
          h2 {
            font-size: 20px;
            color: $dark;
          }

          p {
            color: $dark;
          }
        }
      }
    }

    .tab-content {
      width: 100%;
      margin-#{$left}: 0;

      @include bp(md) {
        margin-#{$left}: 1.5rem;
      }

      .bg_color {
        border-radius: 15px;
        padding: 37px;
        box-shadow: 1px 1px 10px #ddd;
      }
    }
  }

  &-wrapper {
    display: flex;
    flex-direction: column;

    @include bp(md) {
      flex-direction: row;
    }
  }

  .input-container {
    &-check {
      #{$right}: 20px;
      top: 15px;
    }
  }
}

@media (max-width:459px) {
  .nav-pills {
    width: 283px !important
  }
}