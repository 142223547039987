.noData {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    background-color: #FFF;
    padding-top: 30px;
  
    &-cover {
      margin-bottom: 30px;
    }
  
    &-title {
      color: $dark;
      font-size: 24px;
      line-height: 48px;
      color: #718096;
      margin-bottom: 20px;
    }
  
    &-desc {
      color: $dark;
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 20px;
    }
  
    &-actionBtn {
      width: 260px;
      margin-bottom: 30px;
      color: $light;
      background-color: $primary;
    }
  }
  