 



// Tickets Packages
.filterItems {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  &-item {
    padding:5px;
    min-width: 130px;
    border-radius: 50px;
    color: $gray;
    margin-#{$right}: 10px;
    margin-bottom: 10px;
    text-align: center;
    background-color: $light;
    i {
      &:hover {
        cursor: pointer;
      }
    }
  }
}


// addNew Ticket
.addTicket {
  &-creation {
    padding: 20px;
    border-radius: 12px;
  }
}