.l-newTicket {
  background-color: $section-bg;
  padding: 20px 0;

  @include bp(md) {
    padding: 30px 0;
  }

  &-titleWrapper {
    display: flex;
    margin-bottom: 30px;
    align-items: flex-start;
  }

  &-header {
    display: flex;
    background-color: $white;
    border-radius: 16px;
    margin-bottom: 30px;
    flex-direction: column;
    @extend .shadow;
    @include bp(md) {
      height: 100px;
      flex-direction: row;
    }
    &-img {
      border-top-#{$left}-radius: 16px;
      border-top-#{$right}-radius: 16px;
      overflow: hidden;
      @include bp(md) {
        margin-#{$right}: 30px;
        height: 100%;
        width: 250px;

        border-top-#{$left}-radius: 16px;
        border-bottom-#{$left}-radius: 16px;
        border-top-#{$right}-radius: 0;
      }
      .image {
        height: 100%;
        width: 100%;
        @include bp(md) {
          margin-#{$right}: 30px;
        }
      }
    }

    &-text {
      padding: 15px 10px;
      display: flex;
      align-items: center;
      @include bp(sm) {
        padding: 25px;
      }
      @include bp(md) {
        padding: 0;
      }
      .textLabel {
        height: 60px;
        width: 60px;
        border-radius: 16px;
        background-color: $gray;
        color: $light;
        text-align: center;
        padding: 10px 16px;
        font-size: 24px;
        font-weight: bold;
        overflow: hidden;
        margin-#{$right}: 22px;
        span {
          font-size: 12px;
          margin-top: -13px;
          display: block;
        }
      }

      .textTitle {
        font-size: 20px;
        line-height: 25px;
        font-weight: bold;
        @include bp(md) {
          font-size: 48px;
        }
      }
    }
  }

  &-title {
    color: $primary;
    font-size: 26px;
    font-weight: bold;
    line-height: 47px;
  }

  &-desc {
    color: $gray;
    font-size: 14px;
    line-height: 25px;
    margin-bottom: 15px;
  }

  .l-reserveTicketWizard {
    padding: 0;
    border: 1px solid rgba($color: $gray, $alpha: 0.5);
    border-radius: 16px;
    overflow: hidden;
  }

  .reserveTicketWizard-footer {
    margin-bottom: 0;

    .btn {
      &:first-child i::before {
        content: defaultOrReverse("\f30a", "\f30b");
      }
      &:last-child i::before {
        content: defaultOrReverse("\f30b", "\f30a");
      }
    }
  }
}
