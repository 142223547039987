.ticketCard {
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  background-color: $section-bg;

  position: relative;
  overflow-x: auto;
  margin-bottom: 25px;
  flex-direction: column;

  @include bp(md) {
    flex-direction: row;
  }

  &-seperator {
    max-width: 50px;
    flex: 0 0 50px;
    position: relative;
    overflow: hidden;
    display: none;

    @include bp(md) {
      display: block;

    }

    &::after,
    &::before {
      content: "";
      position: absolute;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: $light;
      z-index: 2;
      #{$right}: 0;
    }

    &::after {
      top: -25px;
    }

    &::before {
      bottom: -25px;
    }

  }

  &-dashedLine {
    position: absolute;
    #{$right}: 50%;
    top: 25px;
    width: 1px;
    height: calc(100% - 50px);
    border: 1px dashed rgba($gray, .2);
    z-index: 1;
  }

  &-qrcodDetails {
    padding: 20px;
    display: flex;
    align-items: center;

    @include bp(md) {
      max-width: 300px;
      flex: 0 0 300px;
    }

    .qrcodFrame {
      width: 100px;
      height: 100px;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    .personDetails {
      margin-#{$left}: 15px;

      h4 {
        font-size: 14px;
        color: $dark;
      }

      span {
        display: block;
        font-size: 14px;
        color: rgba($dark, .5)
      }
    }
  }


  &-details {
    padding: 20px;

    @include bp(md) {
      max-width: 650px;
      flex: 0 0 650px;
    }


  }

  &-rowOne,
  &-rowTwo {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @include bp(md) {
      align-items: center;
      flex-direction: row;
    }
  }

  &-rowOne {


    &-ticketType {

      h3 {
        font-size: 16px;
        color: $dark;
      }

      span {
        font-size: 14px;
        color: $gray;
      }
    }

    &-ticketsave {
      display: flex;
      align-items: center;

      // margin-#{$left}: 100px;
      i {
        font-size: 20px;
        color: $info;
      }

      h2 {
        color: $info;
        margin-#{$left}: 10px;
      }
    }

    &-ticketlink {
      display: flex;
      align-items: center;

      i {
        font-size: 20px;
        color: $success;
      }

      h2 {
        color: $success;
        margin-#{$left}: 10px;
      }
    }

  }

  &-rowTwo {


    &-ticketDownload {
      display: flex;
      align-items: center;

      // margin-#{$left}: 100px;
      i {
        font-size: 20px;
        color: $gray;
      }

      h2 {
        color: $gray;
        margin-#{$left}: 10px;
      }
    }

    &-ticketPrint {
      display: flex;
      align-items: center;

      // margin-#{$left}: 100px;
      i {
        font-size: 20px;
        color: $warning;
      }

      h2 {
        color: $warning;
        margin-#{$left}: 10px;
      }
    }

    &-ticketLogin {
      display: flex;
      align-items: center;

      // margin-#{$left}: 100px;
      i {
        font-size: 20px;
        color: $primary;
      }

      h2 {
        color: $primary;
        margin-#{$left}: 10px;
      }
    }

    &-ticketReturn {
      display: flex;
      align-items: center;

      // margin-#{$left}: 100px;
      i {
        font-size: 20px;
        color: $danger;
      }

      h2 {
        color: $danger;
        margin-#{$left}: 10px;
      }
    }
  }


  &-tawakalnaBtn {
    color: $success;
    font-size: 12px;
    line-height: 22px;
    padding: 0;
    padding-#{$left}: 12px;

    &:focus {
      box-shadow: none;
    }
  }
}
