.ticketAccordion {
  background-color: #fff;

  margin-bottom: 10px;

  &-header {
    position: relative;
    padding: 20px;
  }

  &-toggleBtn {
    position: absolute;
    top: 10px;
    #{$right}: 15px;
    padding: 0;

    @include bp(md) {
      top: 30px;
      #{$right}: 35px;
    }

    &:focus {
      box-shadow: none;
    }
  }

  &-label {
    color: $gray;
    font-size: 12px;
    margin-bottom: 10px;
    line-height: 21px;
    min-width: 100px;
  }

  &-value {
    color: $dark;
    font-size: 14px;
    font-weight: bold;
    line-height: 25px;
    margin-bottom: 10px;

    @include bp(md) {
      margin-bottom: 0;
    }
  }

  .titleLabel {
    &-head {
      color: $gray;
    }

    &-info {
      color: $dark;
    }
  }

  .card {
    box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.2);

    &-header {
      background-color: #FFF;
    }
  }

  // tickets step1 accordion
  .accordion>.card:last-of-type {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .accordion>.card:first-of-type {
    border-bottom: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
}


// ticket status
.ticketStatus {
  background: #fff;
  border-radius: 16px;
  margin-bottom: 10px;

  &-type {

    box-shadow: 0px 0px 4px 0px #edecec;
    padding: 15px 18px;
    border-radius: 10px;
  }
}
