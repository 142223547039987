.hvr-underline-from-left {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    #{$left}: 0;
    #{$right}: 100%;
    bottom: 0;
    background: $pri;
    height: 4px;
    transition-property: #{$right};
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }

  &:hover:before,
  &:focus:before,
  &:active:before {
    #{$right}: 0;
  }
}

[class^="hvr-"] {
  margin: 0.4em;
  padding: 1em;
  cursor: pointer;
  background: #e1e1e1;
  text-decoration: none;
  color: #666;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.is-havingVerticalBorder:before {
  content: "";
  height: 100%;
  width: 1px;
  background: rgba($gray, 0.25);
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-50%);
}

// =====================================
.hr-line-1 {
  margin: 20px 0;
}

body {
  overflow-x: hidden;
}

.desc {
  color: $dark;
  font-size: 16px;
  line-height: 27px;
  margin-bottom: 70px;
}

// ===================
.dropdown-menu {
  box-shadow: 0 12px 20px 0 rgb(0 0 0 / 20%);
  border-radius: 16px;
  border: 0;
}

.login-choicesCards {
  margin-bottom: 30px;
}

.choiceCard {
  height: 100%;

  &-input {
    @extend .custom-control-input;

    &:checked {
      + .choiceCard-body {
        border-color: $primary;
        color: $primary;
      }
    }
  }

  &-body {
    cursor: pointer;
    width: 100%;
    border: 1px solid $white;
    border-radius: 16px;
    background-color: $white;
    padding: 30px 15px;
    text-align: center;
    color: $dark;
    box-shadow: 0 4px 8px 0 rgba(15, 40, 55, 0.1);
    margin: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &-icon {
    display: block;
    width: 60px;
    height: 60px;
    margin: 0 auto 22px;

    .image {
      max-width: 100%;
    }
  }

  &-title {
    display: block;
    font-size: 16px;
    font-weight: bold;
    line-height: 28px;
  }
}

.customBg {
  border-radius: 20px;
  background: rgba($gray, 0.1);
  padding: 18px;

  .form-group {
    margin: 0;
  }
}

.boxShadow {
  box-shadow: 0 0 0 15px $gray;
}

.inpSearch {
  position: relative;

  .form-control {
    border-radius: 16px;
  }
}

.optionalChoice {
  margin-block-start: 10px;
  margin-block-end: 10px;
  color: $info;
}

ul {
  list-style: none;
}

.bg-gray {
  background-color: $section-bg;
}

.text-gray {
  color: $gray !important;
}

.custom-padding {
  padding: 1rem 4.5rem !important;
}

.custom-width {
  min-width: 250px;
}

.vertical-middel {
  vertical-align: middle !important;
}

.block {
  border-radius: 16px;
  background-color: #ffffff;

  &-title {
    color: $dark;
    font-size: 20px;
    font-weight: bold;
    line-height: 36px;
    margin-bottom: 20px;
  }

  &-subTitle {
    color: $dark;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 10px;
  }

  &-header {
    padding: 30px 20px;
    border-bottom: 1px solid rgba($gray, 0.25);
  }

  &-body {
    padding: 30px 20px;
  }

  &-footer {
    padding: 30px 20px;
    border-top: 1px solid rgba($gray, 0.25);
  }
}

.mainContent {
  min-height: calc(100vh - 216px);

  &.is-gray {
    background-color: $section-bg;
  }

  .swiper-button-prev,
  .swiper-button-next {
    padding: 18px 20px;
    border: 1px solid rgba($color: $dark, $alpha: 0.2);
    border-radius: 20px;
    box-shadow: 0 0 6px 0px rgba($color: $dark, $alpha: 0.2);
    background-color: #fff;
    color: $dark;
  }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 24px;
    color: inherit;
  }

  .swiper-button-prev:after,
  .swiper-container-rtl .swiper-button-next:after {
    content: "\f104";
  }

  .swiper-button-next:after,
  .swiper-container-rtl .swiper-button-prev:after {
    content: "\f105";
  }
}

.showWrapper {
  position: relative;

  i {
    position: absolute;
    top: 60%;
    left: 5%;
    color: $gray;
    z-index: 99;
  }
}
