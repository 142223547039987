.l-services {

  &-services {
    text-align: center;
    margin-top: 100px;
    margin-bottom: 100px;
  }
 
 
}
