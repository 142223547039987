.l-ourapps {
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;

  &::before {
    content: image("home/apps-left-icon.webp");
    top: 0px;
    #{$right}: 0;
    position: absolute;
  }

  &::after {
    content: image("home/apps-right-icon.webp");
    bottom: 30px;
    #{$left}: 0;
    position: absolute;
  }
}

.ourapps {
  &-secTitle {
    color: $primary;
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 50px;
  }

  &-wrapper {
    .nav-tabs {
      border: none;

      margin: 0px auto;
      justify-content: space-around;
      margin-bottom: 50px;

      @include bp(md) {
        width: 60%;
      }

      .nav-item {
        font-size: 16px;
      }

      .nav-link {
        color: rgba($color: $dark, $alpha: 0.5);
        padding: 20px 10px;
        border: 0;
        border-bottom: 1px solid $light;

        &.active {
          color: $primary;
          border-bottom-color: $primary;
        }
      }
    }
  }

  &-contant {
    padding: 50px 10px;
  }

  &-title {
    color: $dark;
    font-size: 32px;
    margin-bottom: 30px;
  }

  &-desc {
    color: $txt3;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 23px;
  }

  &-tabList {
    list-style-type: none;
    margin-bottom: 45px;
    padding-#{$left}: 0;
  }

  &-tabItem {
    color: $dark;
    font-size: 16px;
    line-height: 44px;

    &::before {
      content: "\f00c";
      font-family: "Font Awesome 5 Free";
      font-weight: 600;
      font-size: 15px;
      color: rgba($color: $primary, $alpha: 0.1);
      background-color: rgba(10, 166, 109, 0.1);
      border-radius: 50%;
      padding: 5px;
      margin-left: 16px;
      font-size: 10px;
    }
  }

  &-img {
    width: 200px;
    padding: 0;
    margin: 0 auto;
  }

  &-cover {
    width: 90%;
  }

  li.active {
    border-bottom: 2px solid $primary;

    .ourapps-link {
      color: $primary !important;
    }
  }
}
