// tickets Info
.ticketInfo {

  &-header {
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    @include bp(md) {
      flex-direction: row;
    }

    &-name {
      display: flex;
      align-items: center;


      a {
        width: 25px;
        height: 25px;
        background-color: rgba($gray, .2);
        text-align: center;
        line-height: 25px;
        border-radius: 50%;
        color: $dark;

        i {
          font-size: 10px;
        }
      }

      h2 {
        margin-#{$left}: 10px;
      }
    }

    &-info {
      display: flex;
      flex-direction: column;

      @include bp(md) {
        flex-direction: row;
      }

      p {
        margin-#{$left}: 35px;
        font-size: 12px;
        color: $gray;

        strong {
          display: block;
          font-size: 14px;
          color: $dark;
        }
      }
    }
  }

  &-details {
    border-radius: 16px;

    margin: 0 auto;
    background-color: #fff;
    padding: 30px 15px;

    @include bp(md) {
      padding: 40px 45px;
    }

    &-quantity {
      width: 80%;
      margin: 0 auto;
      padding: 15px;
      display: flex;
      justify-content: space-between;

      p {
        color: $gray;
        font-size: 14px;

        span {
          color: $dark;
          display: block;
        }
      }

    }

    &-total {
      background-color: rgba($danger, .2);
      display: flex;
      justify-content: space-between;
      padding: 20px 124px;

      h3 {
        font-size: 20px;
        color: $dark;
      }

      h4 {
        font-size: 20px;
        color: $danger;
      }

    }

    &-tickets {

      h2 {
        font-size: 14px;

        color: $dark;
        display: block;
        margin: 20px 0;
      }


    }

  }


}
