.invoice {
    &-userData {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 20px;
        &-name {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 1rem;
            @include bp(md) {
                margin-bottom: 0;
            }
            .arrowIcon {
                background: rgba($gray,0.2);
                border-radius: 50%;
                text-align: center;
                color: $dark;
                width:24px;
                height: 24px;
                line-height: 24px;
                font-size: 10px;
            }
            .userName {
                margin-#{$left}: 15px;
                font-size: 32px;
                margin-bottom: 0;
            }
        }
    }
    .letter {
        background: $light;
        border-radius: 16px;
        
        &-body {
            padding: 25px 10px;
            @include bp(md) {
                padding: 25px 50px;
            }
            .standard {
                font-size: 16px;
                font-weight: 700;
            }
            .props {
              font-size: 16px;
              font-weight: 700;
            }
            .propsInfo {
                font-size: 14px;
                margin-#{$left}: 10px;
                @include bp(md) {
                    margin-#{$left}: 50px;
                }
            }
            .table {
                .emptyRow {
                    height: 30px;
                }
                &-bordered {
                    border: 1px solid $dark;
                }
                td {
                    border: 1px solid $dark;
                    padding:0.25rem;
                }
                .bg-gray-table {
                    background-color: #afafaf;
                }
            }
        }
        .image {
            width: 100%;
        }
        .roundTop {
            border-radius: 16px 16px 0 0;
        }
        .roundBtm{
            border-radius: 0 0 16px 16px;
        }
    }
}
