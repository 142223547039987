.notificationsList {
  padding: 0;
  max-height: calc(100vh - 150px);
  overflow-y: auto;

  &-wrapper {
    border-radius: 16px;
    box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff;
    #{$right}: 30px;
    color: $dark;
    z-index: 10;
    left: 0;
    width: 250px; 

    @include bp(xs) {
      width: 300px;
    }

    @include bp(md) {
      width: 400px;
      position: absolute;
      top: calc(100% + 10px);
      transform: translateX(-50%);
    }
  }

  &-header {
    display: flex;
    justify-content: space-between;
    padding: 30px 30px 20px;
  }

  &-label {
    font-size: 20px;
    font-weight: bold;
    line-height: 36px;
    margin-bottom: 0;
  }

  &-allReadableBtn {
    padding: 0;
    color: $primary;
    font-size: 12px;
    line-height: 21px;
  }


  &-item {
    position: relative;
    padding: 22px;
    padding-#{$left}: 75px;
    text-align: $left;

    &.is-readable {
      background-color: rgba($warning, .1);
    }

  }

  &-cover {
    padding-top: 35px;
    position: absolute;
    top: 0;
    width: 75px;
    text-align: center;
    #{$left}: 0;

    .icon {
      color: rgba($dark, .5);
      font-size: 20px;
    }

  }

  &--title {
    font-size: 14px;
    font-weight: bold;
    line-height: 25px;
    margin-bottom: 5px;
  }

  &--date {
    color: $gray;
    font-size: 12px;
    line-height: 21px;
    text-align: right;
    margin-bottom: 10px;
  }

  &--link {
    color: $primary;
    font-size: 12px;
    font-weight: bold;
    line-height: 21px;
  }
}
