.footer {
  position: relative;
  width: 100%;
  padding: 17px 25px;
  background-color: $white;
  border-top: 1px solid rgba($color: $gray, $alpha: 0.25);
  @include bp(md) {
    padding: 17px 45px;
  }
  &-navbar {
    display: flex;
    padding: 0;
  }

  &-brand {
    width: 60px;
    margin-#{$right}: 50px;
    @media screen and (max-width: 766px) {
      margin: 0 0 10px;
    }
  }

  &-copyRight {
    color: $gray;
    font-size: 14px;
    line-height: 25px;
    margin-bottom: 0;
  }

  &-label {
    color: $dark;
    font-size: 14px;
    font-weight: bold;
    line-height: 25px;
    margin-#{$right}: 15px;
    margin-bottom: 0;
  }

  &-social {
    .nav-item {
      margin-#{$right}: 16px;

      .nav-link {
        padding: 0;
        display: flex;
        color: $dark;
        font-size: 16px;

        &:not(:last-child) {
          margin-right: 16px;
        }
      }
    }
  }
  &-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    @include bp(sm) {
      width: 100%;
      justify-content: space-between;
    }
    @include bp(md) {
      width: inherit;
    }
    @media screen and (max-width: 766px) {
      flex-direction: column;
    }
  }
  &-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    @include bp(md) {
      width: inherit;
    }
  }
  .addtional-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    @include bp(sm) {
      width: initial;
    }
    @include bp(md) {
      justify-content: center;
      width: initial;
    }
    @media screen and (max-width: 766px) {
      flex-direction: column;
      margin: 20px 0 0;
    }
  }
  &-payment {
    margin-#{$right}: 0px;
    @include bp(md) {
      margin-#{$right}: 60px;
    }
    .image {
      height: 50px;
      width: 50px;
      object-fit: contain;
    }
    .nav-item {
      margin-#{$right}: 3px;

      .nav-link {
        padding: 0;
        color: $dark;
        font-size: 16px;

        &:not(:last-child) {
          margin-right: 16px;
        }
      }
    }
  }
}
