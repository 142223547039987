.l-controlPanal-sideNav {
  .accordion {
    height: 100%;
    display: block;
    background: $light;
    border: 0 !important;
    border-radius: 0 !important;
    box-shadow: -1px 11px 20px 1px #e8e8e8;

    .card {
      // width:20%;
      // height: 80vh;

      ul {
        list-style: none;

        li {
          padding: 13px 0;
        }
      }

      .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: transparent;
        border: 0;
        padding: 0;

        button.btn-link {
          color: $dark;
          display: flex;
          justify-content: space-between;
          width: 100%;
          padding: 0.75rem 1.25rem;
          border-radius: 0;

          .card-tab-icon {
            a {
              color: $dark;
            }

            &.is-active a {
              color: $primary;
            }

            span {
              margin-inline-start: 5px;
              display: inline-block;
            }
          }

          &:not(.collapsed) {
            background-color: rgba($primary, 0.2);
            color: $primary;
          }
        }
      }

      .card-body {
        padding: 0 3.25rem;

        ul {
          li {
            position: relative;

            &::before {
              position: absolute;
              content: "";
              width: 8px;
              height: 8px;
              // background-color: #3dbeb4;
              border: 1px solid rgba($gray, 0.5);
              top: 38%;
              right: -15px;
              border-radius: 50%;
            }

            a {
              color: $dark;
            }

            &.subLink-is-active a {
              color: $warning;
            }

            &.subLink-is-active::before {
              background-color: $warning;
              border: 0;
            }
          }
        }
      }
      .collapse.show {
        background-color: #fbfbfb;
      }
    }
  }
}
