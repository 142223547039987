.revenuesCard {
  // Revenues - Average price - statistics 
  width: 100%;
  border-radius: 16px;
  min-height: 371px;
  background-color: #ffffff;
  padding: 25px; 
  margin-bottom: 30px;
  @extend .shadow;
  &-title {
    color: $dark;
    font-size: 16px;
    font-weight: bold;
    display: block;
  }

  &-footer {
    display: flex;
    justify-content: space-between;

    &-ticketsSold {
      span {
        font-size: 12px;
        color: $gray;
      }

      h3 {
        font-size: 24px;
        font-weight: bold;
        color: $primary;
      }
    }

    &-ticketsRefund {
      span {
        font-size: 12px;
        color: $gray;
      }

      h3 {
        font-size: 24px;
        font-weight: bold;
        color: $danger;
      }
    }
  }


}
