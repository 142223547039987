.datepicker-field {
  position: relative;

  .datepicker-toggle {
    position: absolute;
    right: 1rem;
    top: 1.3rem;
    padding: 0;
    border: 0;
    line-height: 1;
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }

    .rtl & {
      right: initial;
      left: 1rem;
    }
  }

  ngb-popover-window {
    border: 0;
    margin: 0 !important;
    max-width: inherit;
    right: 0;
    left: initial !important;

    .ltr & {
      right: initial;
      left: 0 !important;
    }

    .popover-body {
      box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.1);
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .arrow {
      display: none;
    }
  }
}

ngb-datepicker {
  direction: ltr;
  transform: none !important;
  top: 60px !important;

  .rtl & {
    left: initial !important;
    right: 0 !important;
  }

  .custom-select {
    padding: 0 10px !important;
    margin: 0 3px;
    background-position: right 10px center !important;
  }
}
