.l-policy {
  padding-top: 100px;
  padding-bottom: 100px;
}
.policy {

  &-secTitle {
    color: $dark;
    font-size: 48px;
    font-weight: bold;
    text-align: center;
  }

  &-secdesc {
    color: $dark;
    font-size: 16px;
    text-align: center;
    width: 73%;
    margin: 10px auto 30px;
    padding-bottom: 100px;
  }

  &-contant {
    margin-top: 50px;
  }

  &-title {
    color: $primary;
    font-size: 26px;
    font-weight: bold;
  }

  &-list {
    list-style: none;
    padding: 20px 0;
  }
  &-item {
    line-height: 40px;
    font-size: 16px;
    color: $dark;
  }
}
