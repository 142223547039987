.l-ourclients {
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;

  &::before {
    content: image("home/apps-left-icon.webp");
    top: 0px;
    #{$right}: 0;
    position: absolute;
  }

  &::after {
    content: image("home/apps-right-icon.webp");
    bottom: 30px;
    #{$left}: 0;
    position: absolute;
  }
}

.ourclients {
  &-secTitle {
    font-size: 32px;
    font-weight: bold;
    color: $dark;
    margin-bottom: 50px;
  }

  &-slider {
    position: relative;
    padding: 0 60px;
    .swiper-container {
      padding: 10px 0;
      position: static;

      .swiper-wrapper {
        align-items: center;
      }
    }
  }

  &-logo {
    width: 140px;
    height: 140px;
    object-fit: contain;
  }
}
