:root {
  // Custom variable values only support SassScript inside `#{}`.
  @each $color, $value in $colors {
    --#{$color}: #{$value};
  }

  @each $color, $value in $theme-colors {
    --#{$color}: #{$value};
  }

  @each $bp, $value in $grid-breakpoints {
    --breakpoint-#{$bp}: #{$value};
  }

  // Use `inspect` for lists so that quoted items keep the quotes.
  // See https://github.com/sass/sass/issues/2383#issuecomment-336349172
  --font-family-sans-serif: #{inspect($font-family-sans-serif)};
  --font-family-monospace: #{inspect($font-family-monospace)};
}

@each $font in $fonts-map {
  $name: map-get($font, family);
  $weight: map-get($font, weight);
  $weightName: map-get($font, weightName);
  $path: map-get($font, path);

  @if(empty($path) and (empty($weightName) != true)) {
      $path: $fontFolder-path + '/'+ $name + '/'+ $name + '-'+ $weightName
  } @else if(empty($weightName) and (empty($path) != true)) {
      $path: $fontFolder-path + '/' + $path;
  } @else if(  (empty($path) != true) and  (empty($weightName) != true)) {
      $path: $fontFolder-path + '/' + $path +  '-' +   $weightName;
  } @else {
      $path: $fontFolder-path + '/' + $name +  '/' + $name +'-Regular'  ;
  }

  @if(empty($weight) ){$weight: '400';}
  @include setFontFamily($fFamily: #{$name}, $fWeight: #{$weight}, $full-path: #{$path});
}
