// no-tickets styles

.packageReport {
    padding: 30px;
    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        &-title {
            h2 {
                color: $primary;
            }
        }
        &-actions {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            .actions-search {
                width:100%;
                position: relative;
                @include bp(md) {
                    width:50%;
                }
                input {
                    outline:none;
                    text-indent: 30px;
                    width: 100%;
                    height: 60px;
                    border-radius: 8px;
                    border: 1px solid #ececec;
                    color: $gray;
                    margin-block-start: 10px;
                    margin-block-end: 10px;
                    &:active {
                        border: 1px solid #ececec;
                    }
                }
                i {
                    position: absolute;
                    top: 38%;
                    #{$left}: 3%;
                    color: $gray;
                }
            }
            button {
                &:nth-child(2) {
                    margin-inline-start: 5px;
                    margin-inline-end: 5px;
                }
            }
            .btn-light {
                // border-color: $gray !important;
                color: $dark !important;
                padding:15px 9px;
                @media(max-width:768px) {
                    margin-block-end: 5px;
                }
                i {
                    color: $primary;
                    margin-block-start: 5px;
                    margin-block-end: 5px;
                }
            }
        }
    }
    &-report {
        
        &-table {
            .table {
                &-header {
                  background: #ececec;
                  border-radius: 8px;
                  color: $gray;
                }
        
                &-body {
                  background: #FFF;
                  border-radius: 8px;
                  color: $dark;
                  box-shadow: 0 0 10px 0 #ccc;
                  margin-block-start: 10px;
                  margin-block-end: 10px;
        
                  .action {
                    color: $primary;
                  }
        
                }
              }
        }
    }
}

