.ticketSales {
  border-radius: 16px;
  min-height: 371px;
  padding: 25px;
  background-color: #fff;
  margin-bottom: 30px;


  &-header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include bp(sm) {
      align-items: center;
    }

    &-title {
      font-size: 24px;
      font-weight: bold;
      color: $dark;
    }

    &-left {
      display: flex;
      align-items: center;
      flex-direction: column;
      align-items: flex-start;

      @include bp(sm) {
        align-items: inherit;
        flex-direction: row;
      }

      .chartColors {
        position: relative;
        margin-#{$left}: 32px;
        color: $dark;
        font-size: 14px;

        &::after {
          content: '';
          position: absolute;
          width: 10px;
          height: 10px;
          top: 5px;
          #{$left}: -15px;
          border-radius: 50%;
        }

        &:nth-child(1):after {
          background-color: #3DBEB4;
        }

        &:nth-child(2):after {
          background-color: #F89E22;
        }

      }

    }
  }
}
