.statistics {
  width: 100%;
  border-radius: 16px;
  min-height: 371px;
  background-color: #ffffff;
  padding: 30px 20px 20px;
  margin-bottom: 30px;
  @extend .shadow;
  &-title {
    color: $dark;
    font-size: 16px;
    font-weight: bold;
    display: block;
  }

  &-item {
    display: flex;
    align-items: center;
    padding: 11px;
    border-radius: 16px;
    border: 1px solid $section-bg;

    &:not(:last-child) {
      margin-bottom: 20px;

    } 

    &.is-bestSelling .statistics {
      &--icon {
        background-color: rgba($primary, .2);

        i {
          color: $primary;
        }
      }

      &-text {
        color: $primary;
      }
    }

    // total Entry
    &.is-totalEntry .statistics {
      &--icon {
        background-color: rgba($warning, .2);

        i {
          color: $warning;
        }
      }

      &-text {
        color: $warning;
      }
    }

    // number of invitations
    &.is-invitationsNum .statistics {
      &--icon {
        background-color: rgba($danger, .2);

        i {
          color: $danger;
        }
      }

      &-text h3 {
        color: $danger;
      }
    }
  }

  &--icon {
    width: 60px;
    height: 60px;
    background-color: rgba($primary, .2);
    text-align: center;
    line-height: 70px;
    border-radius: 50%;


    i {
      font-size: 25px;
      color: $primary;
    }
  }

  &--text {
    margin-#{$left}: 10px;

    h2 {
      font-size: 12px;
      font-weight: bold;
      color: rgba($dark, .5);
    }

    h3 {
      font-size: 20px;
      font-weight: bold;
      color: $primary;
    }
  } 
}
