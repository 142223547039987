.generalPopup {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background-color: #FFF;
  padding-top: 50px;

  @include bp(md) {
    padding-top: 100px;
  }

  &-cover {
    margin-bottom: 60px;
  }

  &-title {
    color: $primary;
    font-size: 26px;
    font-weight: bold;
    line-height: 45px;
    margin-bottom: 25px;

  }

  &-desc {
    color: $dark;
    font-size: 14px;
    line-height: 28px;
    margin-bottom: 30px;
  }

  &-actionBtn {
    width: 260px;
    margin-bottom: 50px;
    color: $light;
  }
}
