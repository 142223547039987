.l-packagePage {
  position: relative;
  padding-top: 120px;
  background: image("home/circles.png") no-repeat center -100px;
 
}

.l-packages {
  margin-top: 100px;
  margin-bottom: 100px;
  position: relative;

  &::before {
    content: image("home/contact-left-icon.png");
    bottom: 130px;
    #{$right}: 0;
    position: absolute;
    z-index: 0;
  }

  &::after {
    content: image("home/apps-right-icon.png");
    bottom: 30px;
    #{$left}: 0;
    position: absolute;
    z-index: 0;

  }

}

.packages {

  &-formTitle {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-top: 100px;
    margin-bottom: 70px;
  }


  &-groupTitle {
    color: rgba($dark, 0.7);
    font-size: 16px;
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 15px;
  }


  &-btn {
    font-size: 16px;
    font-weight: bold;
    line-height: 28px;
    border-radius: 8px;
    color: $light;

    padding: 15px 40px;

    @include bp(md) {
      padding: 15px 200px;
    }
  }



  &-addpackage {
    border: 1px solid rgba($dark, 0.3);
    padding: 60px 0 80px;
    text-align: center;
    margin-top: 200px;
    margin-bottom: 100px;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(15, 40, 55, 0.1);
    position: relative;
    background-color: #fff;
    z-index: 1;
  }

  &-addtitle {
    font-size: 20px;
    font-weight: bold;
    margin-top: 44px;
    margin-bottom: 20px;
  }

  &-price {
    font-size: 48px;
    font-weight: bold;
    color: $primary;

    span {
      font-size: 14px;
      font-weight: bold;
      color: $dark;
    }
  }

  &-label {
    font-size: 16px;
    letter-spacing: 0;
    color: $danger;
  }

}
