.generalNothing {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height:420px;
    max-width:650px;
    width:95%;
    margin: 20px auto;
    border-radius: 8px;
    box-shadow: 0 0 10px 0 #CCC;
    padding:20px 0;
    &-photo {
        width: 200px;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            max-width: 100%;
        }
    }
    h2 {
        font-weight: bold;
    }
}