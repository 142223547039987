.l-staticModal {
  padding: 30px 15px;
  background-color: #DBDCDE;

  @include bp(sm) {
    padding: 80px 30px;

  }

  @include bp(md) {
    padding: 110px;

  }
}

.staticModal {
  max-width: 800px;
  border-radius: 16px;
  box-shadow: 0 4px 8px 0 rgba(15, 40, 55, 0.1);
  background: $white;
  position: relative;
  margin: 0 auto 60px;

  &.staticModal-md {
    max-width: 1000px;
  }
}
