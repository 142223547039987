.packageCard {

  border: 1px solid rgba($color: $dark, $alpha: 0.5);
  border-radius: 16px;
  background-color: $light;
  padding: 35px 20px;
  min-height: 635px;
  margin-bottom: 40px;
  text-align: center;

  &-bronze {
    border-top: 6px solid $danger;

    .packageCard {
      &-title {
        color: $danger;
      }

      &-price {
        background-color: rgba($color: $danger, $alpha: 0.1);
      }

      &-enterBtn {
        background-color: $danger;
      }
    }

  }

  $silverColor:rgba($color: $dark, $alpha: 0.5);

  &-silver {
    border-top: 6px solid $silverColor;

    .packageCard {
      &-title {
        color: $silverColor;
      }

      &-price {
        background-color: rgba($color: $dark, $alpha: 0.1);
      }

      &-enterBtn {
        background-color: $silverColor;
      }
    }
  }

  &-golden {
    border-top: 6px solid $warning;

    .packageCard {
      &-title {
        color: $warning;
      }

      &-price {
        background-color: rgba($color: $warning, $alpha: 0.1);
      }

      &-enterBtn {
        background-color: $warning;
      }
    }

  }


  &-price {
    padding: 15px;
    background-color: rgba($color: $warning, $alpha: 0.1);
    border-radius: 16px;
    width: 70%;
    margin: 0 auto 20px;
    font-size: 36px;
    font-weight: bold;
    color: $dark;
  }

  &-Currency {
    font-size: 14px;
    font-weight: bold;
  }

  // ============================

  &-title {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  &-enterBtn {
    font-size: 12px;
    color: $light;
    padding: 7px 20px;
    border-radius: 50px;
    margin-bottom: 20px;
    display: inline-block;
    &:hover,&:focus{
      color: $light;
    }

    .icon {
      margin-#{$left}: 15px;
      vertical-align: middle;
    }
  }


  &-list {
    list-style-type: none;
    text-align: #{$left};
    padding-#{$left}: 2px;
    line-height: 25px;
    margin-bottom: 16px;
  }

  &-listItem {
    color: $dark;
    font-size: 15px;
    line-height: 40px;
    position: relative;
    padding-#{$left}: 35px;

    &::before {
      content: "\f00c";
      font-family: "Font Awesome 5 Free";
      font-weight: 600;
      font-size: 15px;
      color: #0AA66D;
      background-color: rgba(10, 166, 109, 0.1);
      border-radius: 50%;
      font-size: 10px;
      position: absolute;
      top: 8px;
      #{$left}: 0;
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
    }
  }







}
