.ng-select.ng-select-single,
.ng-select.ng-select-multiple {
  .ng-select-container {
    // height: calc(1.5em + 2.25rem - 1px) !important;
    padding: 1rem;
    height: calc(1.5em + 1.5rem - 1px) !important;
    border: 1px solid rgba(161, 161, 161, 0.5);
    border-radius: 16px;
    transition: 0.15s ease-in-out;
    min-width: 120px;
    .ng-value-container {
      padding-inline-start: 0;
      padding-inline-end: 10px;
      .ng-placeholder {
        color: $input-placeholder-color;
        font-size: 14px;
      }
      .ng-input {
        top: 12px !important;
        padding-left: 20px;
        padding-right: 20px;
      }
      // .ng-input > input {
      //   padding-left: 10px;
      //   padding-right: 10px;
      // }
    }

    .ng-invalid.ng-touched:not(form) & {
      border-color: $red;
    }
  }

  &.ng-select-focused,
  &.ng-select-opened {
    > .ng-select-container {
      border-color: $primary !important;
      box-shadow: none !important;
    }
  }

  .ng-dropdown-panel {
    border: 1px solid rgba(0, 0, 0, 0.15);
    background-color: white;
    border-radius: 0.5rem;

    box-shadow: 0 12px 20px #0003;
    transform: translateY(10px);
    overflow: hidden;

    .ng-option.ng-option-marked {
      background-color: #f8f9fa;
    }
  }
  &.ng-select-top {
    .ng-dropdown-panel {
      transform: translateY(-10px);
    }
  }

  &.is-invalid .ng-select-container {
    border-color: #e61f36;
  }

  &.ng-select-multiple {
    .ng-select-container .ng-value-container {
      .ng-placeholder {
        top: 20px;
      }
      // .ng-input {
      //   padding-inline-start: 0;
      //   padding-inline-end: 0;
      // }
    }
  }
}

// ng-select .ng-select-container span.ng-arrow-wrapper span.ng-arrow {
//   mask-image: url("./../../images//events/arrow-down.png");
//   mask-size: contain;

//   -webkit-mask-image: url("./../../images//events/arrow-down.png");
//   -webkit-mask-size: contain;

//   height: 24px;
//   top: 3px;
//   width: 24px;
// }

.ng-select .ng-select-container span.ng-arrow {
  display: none;
}
.ng-select .ng-select-container .ng-arrow-wrapper {
  height: 20px;
  width: 20px;
  background-image: url("./../../images/events/arrow-down.png");
  background-repeat: no-repeat;
  padding-left: 0px;
  padding-right: 0px;
}
.ng-select-opened .ng-select-container .ng-arrow-wrapper {
  background-image: url("./../../images/events/arrow-down.png");
  transform: rotate(180deg);
  height: 20px;
  width: 20px;
  padding-left: 0px;
  padding-right: 0px;
}
