.l-hero {
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;

  @include bp(md) {
    padding-bottom: 100px;
    padding-top: 100px;
  }
}

.hero {
  &-details {
    margin-bottom: 30px;

    @include bp(md) {
      margin-bottom: 0;
    }
  }

  &-label {
    color: $txt3;
    font-size: 16px;
  }

  &-title {
    color: $dark;
    font-size: 24px;
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 15px;
    text-align: center;

    @include bp(sm) {
      text-align: inherit;
      padding-top: 20px;
      padding-bottom: 30px;
      font-size: 32px;
    }

    @include bp(md) {
      font-size: 48px;
    }
  }

  &-desc {
    color: $dark;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 15px;
    text-align: justify;

    @include bp(sm) {
      text-align: inherit;
    }

    @include bp(md) {
      margin-bottom: 40px;
      line-height: 29px;
      font-size: 16px;
    }
  }

  &-reserveBtn,
  &-eventsBtn {
    padding: 15px 50px;
    color: $light;
    font-weight: bold;
    width: 100%;
    margin-bottom: 12px;

    @media screen and (max-width: 991px) {
      width: -webkit-fill-available !important;
      margin-bottom: 15px !important;
    }

    @include bp(sm) {
      width: auto;
      margin-bottom: 0;
    }
  }

  &-reserveBtn {
    @include bp(md) {
      margin-#{$right}: 20px;
    }
  }

  &-cover {
    margin-bottom: 30px;

    .image {
      width: 90%;
      height: auto;
    }
  }
}
