.l-general {
  padding-top: 120px;
}

.map {
  width: 100%;
  height: 500px;
  min-height: 500px;
  box-shadow: 0 0 5px 0 #ccc;
  border-radius: 8px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  // background: #CCC;
  &-search {
    position: relative;
    top: 3%;
    width: 95%;
    margin: auto;
    button {
      @include absolute-position(15%, 3%, auto, auto);
      z-index: 999;
      padding: 0.5rem 1.2rem;
    }
  }

  agm-map {
    margin-top: 1rem;
    height: 100%;
  }
}

.uploadWrapper {
  width: 100%;
  height: 300px;
  border: 1px dashed $gray;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  cursor: pointer;

  &:hover {
    border-color: $primary;
  }

  #file-input {
    display: none;
  }

  label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
  }

  .iconWrapper {
    i {
      width: 100px;
      height: 100px;
      color: $primary;
      border: 1px solid $gray;
      border-radius: 50%;
      background: rgba(61, 190, 180, 0.2);
      box-shadow: inset -5px 0 0 white;
      font-size: 30px;
      display: flex !important;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;

      .rtl & {
        box-shadow: inset 5px 0 0 white;
      }
    }
  }

  p {
    color: $dark;
  }

  .img-type {
    color: $gray;
  }

  .browse {
    color: $primary;
    font-weight: bold;
    margin-top: 50px;
  }

  &.has-image {
    position: relative;
    height: auto;
    border-color: transparent;

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    label {
      width: 100%;
      position: absolute;
      height: 100%;

      > * {
        display: none;
      }
    }

    .actions {
      position: absolute;
      bottom: 15px;
      left: 0;
      width: 100%;
      text-align: center;
      z-index: 2;

      .btn {
        background-color: rgba($color: black, $alpha: 0.2);
        color: white;
        font-size: 14px;
        position: static;
        width: auto;
        height: auto;
        display: inline-flex;
        cursor: pointer;
        margin-bottom: 0;

        &:hover {
          background-color: rgba($color: black, $alpha: 0.4);
        }

        & + .btn {
          margin-inline-start: 10px;
        }
      }
    }

    &.landscape {
      &::before {
        content: "";
        display: table;
        width: 100%;
        padding-bottom: 50%;
      }
    }
  }

  &.square {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    padding: 10px;

    p {
      margin: 10px 0 0;
      font-size: 10px;
      font-weight: normal;
      text-align: center;
    }

    .iconWrapper i {
      width: auto;
      height: auto;
      box-shadow: none;
      border: 0;
      background: none;
      margin: 0;
    }

    .actions {
      display: none;
    }
  }
}

.uploadWrapper--files {
  height: auto;
  border: 0;
  .uploadLabel {
    border-radius: 0.5rem;
    border: 1px dashed $gray;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 60px;
    cursor: pointer;
    &:hover {
      border-color: $primary;
    }
  }
}
