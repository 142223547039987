.login {
  margin: 0 auto;
  width: 100%;
  max-width: 450px;

  &.login-select {
    padding-top: 80px;
    padding-bottom: 50px;
    padding-right: 30px;
    padding-left: 30px;
  }

  // ==============
  &-Wrapper {
    padding-top: 30px;
    padding-bottom: 50px;

    @include bp(md) {
      min-height: 100vh;
    }
  }

  &-forgetPassLink {
    color: $dark;
    font-size: 14px;
    line-height: 25px;
    margin-bottom: 60px;
    display: inline-block;
  }

  &-social {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 30px;

    &-label {
      color: $dark;
      font-size: 12px;
      font-weight: bold;
      line-height: 21px;
      margin-bottom: 15px;
      text-align: center;
    }

    &-list {
      justify-content: space-between;
    }

    &-item {
      &:not(:last-child) {
        //  margin-#{$right}: 20px;
      }
    }

    &-link {
      display: inline-block;
      font-size: 21px;
      border: 1px solid rgba($gray, 0.5);
      border-radius: 16px;
      color: $dark;
      background-color: $white;
      @include trans();
      padding: 8px 15px;

      @include bp(md) {
        padding: 13px 20px;
      }
    }
  }

  //   ===========================
  &-noAccountLink {
    color: $gray;
    font-size: 12px;
    line-height: 21px;
    margin-bottom: 18px;
    display: inline-block;
    text-align: center;
  }

  // ==========================
  &-hint {
    color: $dark;
    font-size: 14px;
    line-height: 25px;
  }

  &-choicesCards {
  }

  &-nextBtn {
    // @extend .btn;
    border: 1px solid rgba($gray, 0.5);
    box-shadow: 0 4px 8px 0 rgba(15, 40, 55, 0.1);
    border-radius: 16px;
    width: 100%;
    color: $primary;
    background-color: $white;
    line-height: 28px;
    position: relative;
    padding-#{$left}: 50px;
    margin-bottom: 30px;

    .icon {
      position: absolute;
      #{$right}: 33px;
      top: 50%;
      transform: translateY(-50%);
      margin: 0 !important;

      &::before {
        content: defaultOrReverse("\f30b", "\f30a");
      }
    }

    .text {
    }
  }

  //    card status
  &.login-card {
    border-radius: 16px;
    box-shadow: 0 4px 8px 0 rgba(15, 40, 55, 0.1);
    border: 1px solid #ffffff;

    background-color: #ffffff;
  }

  &-header {
    padding: 18px 30px;
    border-bottom: 1px solid rgba($gray, 0.5);

    &-type {
      font-size: 16px;
      font-weight: bold;
      line-height: 28px;
      margin-bottom: 0;
      display: flex;
      align-items: center;

      .icon {
        width: 30px;
        margin-#{$right}: 8px;
      }
    }

    &-backLink {
      color: $gray;
      font-size: 14px;
      line-height: 25px;
      display: flex;
      align-items: center;

      .icon {
        width: 24px;
        margin-#{$right}: 8px;

        .rtl & {
          transform: scaleX(-1);
        }
      }
    }
  }

  &-body {
    padding: 20px 30px;
  }

  &-capatcha {
    border: 1px solid rgba($gray, 0.5);
    border-radius: 16px;
    background-color: $white;
    padding: 2px 20px;
    margin-bottom: 20px;
  }
}
