.l-aboutusPage {
  position: relative;
  padding-top: 120px;
  background: image("home/hero-bg-lines.webp") no-repeat center -100px;
}

.l-statisticsCards {
  position: relative;
  background: image("home/blue-dots-lg.png") no-repeat calc(100% + 100px) 56px /
    contain;
  background-size: 220px 190px;
}

.l-aboutus {
  padding-top: 100px;
  padding-bottom: 100px;
}

.aboutus {
  &-secTitle {
    color: $dark;
    font-size: 48px;
    font-weight: bold;
    text-align: center;
  }

  &-secdesc {
    color: $dark;
    font-size: 16px;
    text-align: center;
    width: 73%;
    margin: 10px auto 30px;
    padding-bottom: 100px;
  }

  &-stats {
    margin-top: 200px;
    margin-bottom: 200px;
    text-align: center;
  }

  &-title {
    color: $dark;
    font-size: 32px;
    font-weight: bold;
    text-align: center;
  }

  &-statscontent {
    background-color: #fff;
    height: 161px;
    width: 161px;
    padding: 30px;
    border-radius: 50%;
    border: 1px solid rgba($color: $dark, $alpha: 0.1);
    margin: 0 auto;
    margin-bottom: 30px;
  }

  &-statsevent {
    color: $primary;
    border-#{$left}: 4px solid $primary;
    border-top: 4px solid $primary;
  }

  &-statsticket {
    color: $warning;
    border-#{$left}: 4px solid $warning;
    border-top: 4px solid $warning;
    border-bottom: 4px solid $warning;
  }

  &-statsinvit {
    color: $danger;
    border-#{$left}: 4px solid $danger;
    border-top: 4px solid $danger;
    border-bottom: 4px solid $danger;
  }

  &-statscoupon {
    color: $info;
    border-#{$left}: 4px solid $info;
    border-top: 4px solid $info;
  }

  &-statsicon {
    color: $light;
    width: 24px;
    height: 24px;
    margin: 0 auto;
    border: 1px solid $dark;

    i {
      font-size: 9px;
    }
  }

  &-statsnum {
    font-size: 30px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 0;
  }

  &-statsdesc {
    font-size: 12px;
    color: rgba($color: $dark, $alpha: 0.6);
  }

  &-services {
    text-align: center;
    margin-top: 200px;
    margin-bottom: 200px;
  }

  &-desc {
    color: $txt3;
    font-size: 14px;
  }

  &-icon {
    height: 70px;
    width: 70px;
    margin-top: 50px;
  }

  &-cardTitle {
    color: $dark;
    font-size: 20px;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 15px;
  }

  &-cardDesc {
    color: $txt3;
    font-size: 14px;
    padding: 5px 55px 30px;
  }

  &-moreBtn {
    padding: 15px 50px;
    color: $dark;
    border: 1px solid rgba($color: $dark, $alpha: 0.2);
    font-size: 16px;
    font-weight: bold;
    border-radius: 15px;
  }

  &-appstitle {
    color: $primary;
    font-size: 32px;
    font-weight: bold;
    text-align: center;
  }

  &-list {
    border: none;
    width: 60%;
    margin: 0px auto;
  }

  &-item {
    font-size: 16px;
  }

  &-link {
    color: rgba($color: $dark, $alpha: 0.5);
    padding: 20px 10px;
    border: 1px solid $light !important;
  }

  &-contant {
    padding: 50px 10px;
  }

  &-title {
    color: $dark;
    font-size: 32px;
    padding-bottom: 30px;
  }

  &-desc {
    color: $txt3;
    font-size: 16px;
  }

  &-tabList {
    list-style-type: none;
  }

  &-tabItem {
    color: $dark;
    font-size: 16px;
    line-height: 44px;

    &::before {
      content: "\f00c";
      font-family: "Font Awesome 5 Free";
      font-weight: 600;
      font-size: 15px;
      color: #0aa66d;
      background-color: rgba(10, 166, 109, 0.1);
      border-radius: 50%;
      padding: 5px;
      margin-left: 8px;
      font-size: 10px;
    }
  }

  &-img {
    width: 200px;
    padding: 0;
    margin: 0 auto;
  }

  &-cover {
    width: 90%;
  }
}

.aboutus {
  label {
    color: $txt3;
    font-size: 14px;
  }

  input,
  textarea {
    height: 60px;
    border: 1px solid $txt3;
    border-radius: 8px;

    &::placeholder {
      color: $dark;
      font-size: 14px;
    }
  }

  &-btn {
    padding: 15px 200px;
    font-size: 16px;
    font-weight: bold;
    line-height: 28px;
    border-radius: 8px;
    color: $light;
  }

  &-icon {
    color: $txt3;
    font-size: 20px;
    margin-#{$right}: 15px;
  }

  &-info {
    display: inline-block;
    color: $txt3;
    font-size: 16px;
    text-align: center;
  }

  li.active {
    border-bottom: 2px solid $primary;

    .ourapps-link {
      color: $primary !important;
    }
  }
}
