.l-auth {
  display: flex;
  flex-direction: column-reverse;

  @include bp(md) {
    flex-direction: row;
  }

  &-mainWrapper {
    background-color: $section-bg;

    @include bp(md) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  &-sliderWrapper {
    background: $body-bg image("login/login-bg.png");
    background-position: top;
    background-size: cover;

    padding-top: 30px;
    padding-bottom: 30px;

    swiper {
      display: flex;
      flex-direction: column-reverse;
    }
    .swiper-pagination {
      margin-top: 100px;
      position: static;
    }

    @include bp(md) {
      flex: 0 0 50%;
      max-width: 50%;
      padding-top: 200px;
      padding-bottom: 100px;
    }
  }

  &-slider {
    width: 280px;
    margin: 0 auto;

    @include bp(md) {
      width: 430px;
    }

    .swiper {
      &-container {
        padding-bottom: 50px;
      }

      &-slide {
        background-color: transparent;
      }

      &-pagination-bullet {
        border-radius: 16px;
        width: 30px;
        height: 3px;
        margin: 0 5px;
        transition: width 0.3s;

        &-active {
          width: 60px;
        }
      }
    }
  }

  &-cover {
    margin: 0 auto 25px;
    width: 150px;

    @include bp(md) {
      margin-bottom: 60px;
    }

    .image {
      width: 100%;
    }
  }

  &-desc {
    font-size: 16px;
    font-weight: bold;
    line-height: 29px;
    text-align: center;
  }
}

.ng-otp-input-wrapper {
  display: flex;
  justify-content: center;
  .otp-input {
    width: 70px !important;
    height: 70px !important;
    border-radius: 8px !important;
    outline: 0;
    transition: 0.3s;
    margin: 0 4px !important;

    &:focus {
      border-color: $primary !important;
    }
    @media screen and (max-width: 767px) {
      width: 50px !important;
      height: 50px !important;
    }
  }

  .rtl & {
    flex-direction: row-reverse;
  }
}

ngx-intl-tel-input {
  display: flex;
  width: 100%;
  .iti {
    width: 100%;
  }

  &.is-invalid {
    .form-control {
      border-color: $danger;
    }
  }
  .rtl & {
    .form-control {
      direction: ltr;
      text-align: right;
    }

    .iti__arrow {
      margin-right: 6px;
      margin-left: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 6px solid #555;
    }
    .dropdown-menu {
      right: 0 !important;

      ul {
        text-align: right !important;
        direction: ltr;
      }
    }
  }
}

.email-not-verified {
  background-color: $primary;
  color: white;
  text-align: center;
  padding: 10px;

  a {
    color: $dark;
    text-decoration: underline;
  }
}
