.allowedOption {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 1.5rem;
    border-top: 1px solid rgba($gray, 0.3);
    padding-top: 2rem;
}
.optionalSetting {
    margin-bottom: 1.5rem;
    border-top: 1px solid rgba($gray, 0.3);
    padding-top: 2rem;
}