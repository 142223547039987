.l-contactus {
  position: relative;
  position: relative;
  z-index: 1;
  &::before {
    content: image("home/apps-left-icon.webp");
    top: 0px;
    #{$right}: 0;
    position: absolute;
    z-index: 0;
  }

  &::after {
    content: image("home/apps-right-icon.webp");
    top: 20px;
    #{$left}: -40px;
    position: absolute;
    z-index: 0;
  }
}

.contactus {
  &-secOne {
    background-color: $section-bg;
    padding-top: 100px;
    padding-bottom: 100px;
  }

  &-title {
    color: $dark;
    font-size: 32px;
    font-weight: bold;
  }

  &-label {
    font-size: 16px;
    line-height: 27px;
    text-align: center;
    margin-bottom: 60px;
  }

  &-wrapper {
    label {
      color: $txt3;
      font-size: 14px;
    }

    input,
    textarea {
      height: 60px;
      border: 1px solid rgba($txt3, 0.7);
      border-radius: 8px;

      &::placeholder {
        color: $dark;
        font-size: 14px;
      }
    }
  }

  &-btn {
    padding: 15px 200px;
    font-size: 16px;
    font-weight: bold;
    line-height: 28px;
    border-radius: 8px;
    color: $light;
  }

  &-secTwo {
    margin-top: 150px;
  }

  &-content {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  &-title {
    font-size: 32px;
    font-weight: bold;
    line-height: 58px;
    color: $dark;
  }

  &-desc {
    font-size: 16px;
    line-height: 28px;
    color: $txt3;
  }
}
