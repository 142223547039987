.toast {
  position: relative;
  box-shadow: none;
  min-width: 200px;
  border: 0;
  border-radius: 8px;
  border-inline-end: 5px solid transparent;

  &.toast-error {
    background-color: rgba($color: $danger, $alpha: 0.1);
    border-color: $danger;

    .toast-header {
      color: $danger;
    }

    .toast-title {
      color: $danger;
      i {
        background-color: rgba($color: $danger, $alpha: 0.25);
        color: $danger;
      }
    }
  }
  &.toast-success {
    background-color: rgba($color: $success, $alpha: 0.1);
    border-color: $success;

    .toast-header {
      color: $success;
    }

    .toast-title {
      color: $success;
      i {
        background-color: rgba($color: $success, $alpha: 0.25);
        color: $success;
      }
    }
  }
}
.toast-header {
  background-color: transparent;
  padding: 0;

  .close {
    margin: 0;
    position: absolute;
    right: 10px;
    top: 10px;
    text-shadow: none;
    width: 24px;
    height: 24px;
    font-size: 14px;

    .rtl & {
      left: 10px;
      right: initial;
    }
  }
}
.toast-title {
  display: flex;
  align-items: center;

  i {
    width: 24px;
    height: 24px;
    border: 1px solid black;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: inset -1px 0 white;
    margin-inline-end: 10px;
    background-color: black;
    color: white;

    .rtl & {
      box-shadow: inset 1px 0 white;
    }
  }

  h3 {
    font-size: 16px;
    margin: 0;
  }
}
.toast-body {
  display: flex;
  flex-direction: column;
  padding: 20px 15px;

  p {
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 0;
  }
}

.ngb-toasts {
  .rtl & {
    left: 0;
    right: initial;
  }
}
