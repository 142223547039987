.l-clients {
  padding-top: 200px;
  padding-bottom: 200px;
}

.clients {
  &-secTitle {
    font-size: 32px;
    font-weight: bold;
    color: $dark;
    margin-bottom: 50px;
  }

  &-logos {
    text-align: center;
  }


  &-logo {
    width: 160px;
    margin: 0 20px;
  }

  &-arrow {
    padding: 18px 20px;
    border: 1px solid rgba($color: $dark, $alpha: 0.2);
    border-radius: 20px;
    box-shadow: 0 0 6px 0px rgba($color: $dark, $alpha: 0.2);
  }

}
