.l-reserveTicketWizard {
  padding-top: 70px;
  padding-bottom: 50px;
}

.reserveTicketWizard {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: $light;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  &-title {
    color: $dark;
    font-size: 26px;
    font-weight: bold;
    line-height: 47px;
  }

  &-tabs {
    border-bottom: 1px solid rgba($color: $gray, $alpha: 0.25);
    font-size: 14px;
    line-height: 23px;
    padding: 20px 15px;

    @include bp(md) {
      padding: 40px 60px;
    }
  }

  &-items {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-right: 0;
    margin-bottom: 0;
    list-style: none;
  }

  &-item {
    position: relative;
    // margin-bottom: 32px;

    // &:not(:last-child) {
    //   &:after {
    //     content: "";
    //     position: absolute;
    //     #{$left}: 19.5px;
    //     top: calc(100% + 5px);
    //     display: block;
    //     height: 20px;
    //     width: 1px;
    //     z-index: 10;
    //     opacity: 0.5;
    //     background-color: $gray;

    //   }
    // }

    &.active {
      .reserveTicketWizard-step {
        border-color: darken(desaturate(adjust-hue($primary, -349deg), 71), 17);

        .number {
          background-color: $primary;
        }
      }

      .reserveTicketWizard-label {
        color: $dark;
        font-weight: bold;
      }
    }

    // ====================================
    &.active ~ .reserveTicketWizard-item {
      .reserveTicketWizard-step {
        border-color: $gray;

        .number {
          background-color: rgba($color: $gray, $alpha: 0.1);
          color: $dark;
        }
      }

      .reserveTicketWizard-label {
        color: $dark;
      }
    }
  }

  &-label {
    color: $success;
    font-size: 16px;
    line-height: 28px;
    display: none;

    @include bp(md) {
      display: inline;
    }
  }

  &-step {
    display: inline-block;
    width: 40px;
    height: 40px;
    border: 1px solid $success;
    padding: 8px;
    border-radius: 50%;
    margin-bottom: 0;

    @include bp(sm) {
      margin-#{$right}: 15px;
    }

    .number {
      width: 22px;
      height: 22px;
      background-color: $success;
      color: #fff;
      display: inline-block;
      text-align: center;
      line-height: 25px;
      border-radius: 50%;
    }
  }

  &-icon {
    margin-#{$right}: 5px;

    @include bp(xs) {
      margin-#{$right}: 10px;
    }

    @include bp(sm) {
      margin-#{$right}: 15px;
    }

    @include bp(md) {
      margin-#{$right}: 25px;
    }
  }

  // =====================
  &-content {
    padding: 20px 15px;

    @include bp(md) {
      padding: 30px;
    }

    &-title {
      font-size: 20px;
      font-weight: bold;
      line-height: 36px;
      margin-bottom: 35px;
      text-align: center;
    }

    &-insideTitle {
      font-size: 20px;
      font-weight: bold;
      line-height: 36px;
      margin-bottom: 35px;
    }

    input::placeholder {
      color: $dark;
    }

    .visitorInfo {
      border: 1px solid rgba($gray, 0.5);
      border-radius: 16px;
      padding: 38px 15px;

      @include bp(md) {
        padding: 38px 85px;
      }
    }
  }

  &-footer {
    border-top: 1px solid rgba($color: $gray, $alpha: 0.25);
    display: flex;
    justify-content: space-between;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    background-color: $light;
    margin-bottom: 35px;
    // flex-direction: column;
    padding: 20px 15px;

    @include bp(md) {
      padding: 40px 50px;
      // flex-direction: row;
    }
  }
}

.days-wrapper {
  position: relative;
  padding: 0 50px;
  > .btn {
    position: absolute;
    top: 50%;
    margin-top: -20px;
    transform: scaleX(-1);
    right: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: white;
    padding: 0;
    box-shadow: 2px 4px 8px rgba($color: #000000, $alpha: 0.1);

    &:hover {
      background-color: $primary;
      color: white;
    }
    &.go-prev {
      right: initial;
      left: 0;
    }
  }
}

.event-days {
  display: flex;
  // justify-content: center;
  overflow: auto;
  margin-bottom: 30px;
}
@media screen and (max-width: 599px) {
  .event-days__day {
    padding: 7px !important;
    span:not(.skeleton-box) {
      font-size: 18px !important;
    }
  }
}

.event-days__day {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  margin: 0 10px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s, color 0.3s;
  min-width: 100px;
  text-align: center;
  span:not(.skeleton-box) {
    font-weight: bold;
    display: block;
    font-size: 21px;
    width: 100%;
  }

  &.selected {
    background-color: $primary;
    color: white;
  }
}
