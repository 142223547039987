.previewImage {
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  // flex-wrap: wrap;
  // width: 70%;
  &-vertical {
    background: #fff;
    box-shadow: 0 0 5px 0 #ccc;
    width: 100%;
    border-radius: 8px;
    margin: 10px 0;
    overflow: hidden;
    margin-bottom: 30px;

    .top {
      position: relative;
      &::before {
        content: "";
        display: table;
        width: 100%;
        padding-bottom: 50%;
      }
      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
    .image {
      width: 100%;
      height: 100%;
    }
  }
  &-horiz {
    width: 100%;
    display: flex;
    margin: 10px 0;
    background: #fff;
    box-shadow: 0 0 5px 0 #ccc;
    border-radius: 8px;
    overflow: hidden;
    align-items: flex-start;
    .right {
      width: 190px;
      position: relative;
      @include bp(sm) {
        width: 390px;
      }

      &::before {
        content: "";
        display: table;
        width: 100%;
        padding-bottom: 50%;
      }
      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
    .image {
      width: 100%;
      height: 100%;
    }

    .left {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      @include bp(sm) {
        width: 200px;
      }
    }
  }
}
