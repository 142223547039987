// ===================================== floating form control
.btn {
  &-primary {
    color: #ffffff;
  }

  &-light {
    @extend .shadow;
  }

  &-gray {
    background: $gray;
  }

  &-cst {
    box-shadow: inset 1px 1px $light;
    padding: 0.8rem 1.4rem;
  }

  &-outline-gray {
    border-color: rgba($gray, 0.5);
  }

  &-cstLink {
    background: rgba($gray, 0.1);

    a {
      color: $dark;
    }

    @media (max-width: 425px) {
      padding: 1rem 0.6rem;
      font-size: 0.8rem;
    }
  }

  &-loading {
    font-size: 0;
    &::before {
      @extend .spinner-border;
      content: "";
      border-width: 3px;
      width: 1.5rem;
      height: 1.5rem;
      border-color: white;
      border-left-color: transparent;
    }
  }

  &:disabled {
    pointer-events: none;
  }
}

.flatpickr-input {
  &.ng-invalid.ng-dirty ~ .form-control {
    border-color: $red;
  }
}

.form-control {
  // height: 60px;
  &.is-valid,
  &.is-invalid {
    background-size: 1.2rem;
  }

  &[type="number"] {
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }

  // .rtl & {
  //   direction: ltr;
  //   text-align: right;
  // }

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #999 !important;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: #999 !important;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: #999 !important;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: #999 !important;
  }
}

textarea.form-control {
  resize: none;
  height: 175px;
}

.evento-form-error {
  color: $danger;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-top: 0.625rem;
  display: flex;
  i {
    font-size: 1.25rem;
    margin-inline-end: 0.625rem;
  }
}
.form-group {
  position: relative;
  margin-bottom: 18px;

  .datepicker-field {
    .form-control {
      &.is-valid,
      &.is-invalid {
        background-image: none;
      }
    }
  }

  .custom-select {
    .rtl & {
      background-position: left 1rem center;
      padding: 1.125rem 1rem 1.125rem 2rem;
    }
  }

  @at-root .form-label,
    & label,
    .control-label {
    color: $gray;
    font-size: 14px;
    line-height: 25px;
    margin-bottom: 10px;
  }

  &.is-havingIcon {
    position: relative;

    .form-control {
      padding-#{$left}: 50px;

      &:focus + .form-icon {
        color: $primary;
      }
    }

    .form-icon {
      position: absolute;
      top: 16px;
      #{$left}: 16px;
      font-size: inherit;
      color: $gray;
    }

    &.is-error {
      border-color: $danger;
      color: $danger;
    }
  }

  &.is-havingPasswordIcon {
    position: relative;

    .form-control {
      padding-#{$right}: 50px;

      &:focus + .form-icon {
        color: $primary;
      }
    }

    .form-icon {
      position: absolute;
      top: 50px;
      #{$right}: 16px;
      font-size: inherit;
      color: $gray;
    }

    &.is-error {
      border-color: $danger;
      color: $danger;
    }
  }

  &-append {
    position: absolute;
    top: 7px;
    #{$right}: 7px;
    height: 45px;
    .btn {
      padding: 0.6rem 2rem;
      max-height: 100%;
    }

    &.is-noPadding {
      top: 0;
      #{$right}: 0;
      height: 59px;
    }
  }
}

.btn-gray {
  color: #fff;
  background-color: $gray;
  border-color: $gray;
}
.btn-gray2 {
  color: $dark;
  background-color: rgba($color: $gray, $alpha: 0.1);
  border-color: rgba($color: $gray, $alpha: 0.5);
}

.dropdown-noArrow.dropdown-toggle {
  &::after {
    display: none !important;
  }

  &:focus {
    box-shadow: none;
  }
}

.dropdown-menu {
  right: 0 !important;
  left: initial !important;
  [dir="rtl"] & {
    left: 0 !important;
    right: initial !important;
  }

  .table & {
    transform: none !important;
  }
}

.table-default .dropdown {
  .dropdown-menu {
    border-radius: 16px;
    box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.2);
    padding: 0;
    border: 0;

    .dropdown-item {
      padding-top: 11px;
      padding-bottom: 11px;

      &:first-child {
        border-top-#{$left}-radius: 16px;
        border-top-#{$right}-radius: 16px;
      }

      &:last-child {
        border-bottom-#{$left}-radius: 16px;
        border-bottom-#{$right}-radius: 16px;
      }
    }
  }
}

// =============================
// table style
.table-responsive {
  padding-inline-end: 5px;
  overflow-x: visible;
  @include bp(md) {
    padding-inline-end: 0;
  }
}
.table.table-default {
  border-collapse: inherit;
  border-spacing: 0 10px;

  thead th,
  tbody td {
    padding: 15px 20px;
    border: 0;

    @include bp(md) {
      &:first-child {
        border-top-#{$left}-radius: 16px;
        border-bottom-#{$left}-radius: 16px;
      }

      &:last-child {
        border-top-#{$right}-radius: 16px;
        border-bottom-#{$right}-radius: 16px;
      }
    }
  }

  thead th {
    background-color: rgba($color: $gray, $alpha: 0.1);
    color: #a1a1a1;
    font-size: 12px;
    line-height: 24px;
  }

  tbody tr {
    box-shadow: 0 4px 8px 0 rgba(15, 40, 55, 0.1);
    border-radius: 16px;
  }

  tbody td {
    background-color: #fff;
    font-weight: bold;
    font-size: 14px;
    line-height: 25px;
    border: 0;
  }

  .table-minimizeToggle {
    position: absolute;
    top: 10px;
    left: 20px;
    font-size: 20px;
    color: $primary;

    @include bp(md) {
      display: none;
    }
  }

  .table-minimizeToggle::before {
    content: "\f0d8";
  }

  @include bp(isCommon) {
    thead th {
      display: none;
    }

    tbody tr {
      display: block;
      margin-bottom: 0.625em;
    }

    tbody td {
      padding-top: 10px;
      padding-bottom: 10px;
      position: relative;
      font-size: 0.8em;
      display: flex;
      width: 100% !important;
      align-items: center;
      justify-content: flex-start;
      text-align: $right;

      &.is-closed ~ td {
        display: none;
      }

      &:before {
        content: attr(data-label);
        margin-#{$right}: auto;
        font-weight: bold;
        text-transform: uppercase;
        color: #a1a1a1;
        font-size: 12px;
        line-height: 24px;
        padding-#{$right}: 10px;
        min-width: 30%;
        text-align: $left;
      }

      &:after {
        content: "";
        display: block;
        clear: both;
      }

      &:first-child {
        padding-top: 36px;
        border-top-#{$left}-radius: 12px;
        border-top-#{$right}-radius: 12px;

        &.is-closed {
          border-radius: 12px;

          .table-minimizeToggle::before {
            content: "\f0d7";
          }
        }
      }

      &:last-child {
        border-bottom-#{$left}-radius: 12px;
        border-bottom-#{$right}-radius: 12px;
      }
    }
  }
}

// ========================
.close:focus {
  outline: 0;
}

.breadcrumb-item + .breadcrumb-item::before {
  font-size: 10px;
}

.custom-control-label {
  display: block;
}

.ltr
  .evento-checkbox
  input[type="checkbox"]:disabled
  + .evento-checkbox__checkmark::before {
  top: 45%;
}

.rtl
  .evento-checkbox
  input[type="checkbox"]:disabled
  + .evento-checkbox__checkmark::before {
  top: 57%;
}
