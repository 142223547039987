 // Latest Users Added
 .latestUsersAdded {

    border-radius: 16px;
    min-height: 371px;
    padding: 25px;
    background-color: #fff;
    margin-bottom: 30px;
    @extend .shadow;
    &-title {
      font-size: 16px;
      font-weight: bold;
      color: $dark;
      margin-bottom: 15px;
    }

    &-info {
      display: flex;
      align-items: center;
      padding: 8px 0;
      border-bottom: 1px solid rgba($gray, .2);

      &-image {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        overflow: hidden;
        margin-#{$right}: 20px;
        background-color: #ddd;

        img {
          width: 100%;
          opacity: .5;
        }
      }

      &-details {
        flex: 1;
        h2 {
          font-size: 14px;
          font-weight: bold;
          color: $dark;
        }

        p {
          font-size: 12px;
          color: $gray;
          line-height: 0;
        }
      }

      &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
