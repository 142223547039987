  // Latest tickets sold Table
  .latestTicketsSoldTable {
    max-width: 100%;
    border-radius: 16px;
    min-height: 371px;
    background-color: #ffffff;
    padding: 25px;
    margin-bottom: 30px;
    @extend .shadow;
    .dailyReport {
      padding: 30px;
      box-shadow: $box-shadow;

      &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-title {
          h2 {
            color: $primary;
          }
        }

        &-actions {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 45%;

          .actions-search {
            width: 60%;
            position: relative;

            input {
              outline: none;
              text-indent: 30px;
              width: 100%;
              height: 60px;
              border-radius: 8px;
              border: 1px solid #ececec;
              color: $gray;
              margin-block-start: 10px;
              margin-block-end: 10px;

              &:active {
                border: 1px solid #ececec;
              }
            }

            i {
              position: absolute;
              top: 38%;
              #{$left}: 3%;
              color: $gray;
            }
          }

          button {
            &:nth-child(2) {
              margin-inline-start: 5px;
              margin-inline-end: 5px;
            }
          }

          .btn-light {
            border-color: $gray !important;
            color: $dark !important;

            i {
              color: $primary;
              margin-block-start: 5px;
              margin-block-end: 5px;
            }
          }
        }
      }

      &-report {

        &-table {
          .reportCard {
            display: flex;
            flex-direction: column;
            padding: 0;

            // width:1050px;
            &-header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              background: #ececec;
              padding: 15px;
              border-radius: 8px;
              color: $gray;
            }

            &-body {
              display: flex;
              justify-content: space-between;
              align-items: center;
              background: #FFF;
              padding: 15px;
              border-radius: 8px;
              color: $dark;
              font-weight: bold;
              box-shadow: 0 0 10px 0 #ccc;
              margin-block-start: 10px;
              margin-block-end: 10px;

              &-ticketAction {
                color: $primary;
              }

            }
          }
        }
      }
    }



  }