.l-gray {
    padding: 20px 15px;
    background-color: $section-bg;
  
    @include bp(sm) {
      padding: 20px;
  
    }
  
    @include bp(md) {
      padding: 30px 45px;
  
    }
  }