.title {
  color: $dark;
  font-size: 48px;
  font-weight: bold;
  line-height: 86px;
  margin-bottom: 10px;

  &-2 {
    color: $dark;
    font-size: 32px;
    font-weight: bold;
    line-height: 58px;
    margin-bottom: 55px;
  }

  &-3,
  &-text {
    color: $dark;
    font-size: 26px;
    font-weight: bold;
    line-height: 47px;
    margin-bottom: 30px;
  }

  //  =====================
  &-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
    justify-content: space-between;
    text-align: center;

    @include bp(xs) {
      text-align: $left;
    }

    .title-text {
      margin-bottom: 10px;

      @include bp(md) {
        margin-bottom: 0;
      }
    }
  }

  &-actionWrapper {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;

    @include bp(xs) {
      flex-direction: row;
    }
  }

  &-action {
    &.dropdown > .btn,
    &.btn {
      font-weight: bold;
      padding-top: 1.03rem;
      padding-bottom: 1.03rem;
    }

    &:not(:last-child) {
      margin-bottom: 10px;

      @include bp(xs) {
        margin-#{$right}: 20px;
        margin-bottom: 0px;
      }
    }
  }
}
