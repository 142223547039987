.l-reset {
  padding: 110px 30px;
  background: #DBDCDE image('login/login-bg.png') top/cover;
}

.reset {
  width: 100%;
  max-width: 730px;
  border-radius: 16px;
  box-shadow: 0 4px 8px 0 rgba(15, 40, 55, 0.1);
  background: $white;
  position: relative;
  margin: 0 auto 60px;


  &-backLink {
    position: absolute;
    top: 30px;
    #{$right}: 30px;
  }

  &-body {
    padding-top: 80px;
    padding-bottom: 60px;
    padding-right: 30px;
    padding-left: 30px;
    width: 100%;
    max-width: 360px;
    margin: 0 auto;
  }

  &-cover {
    margin: 0 auto 40px;
    width: 90px;

    .image {
      max-width: 100%;
    }
  }

  &-title {
    color: $dark;
    font-size: 32px;
    font-weight: bold;
    line-height: 58px;
    margin-bottom: 25px;
    text-align: center;
  }

  &-desc {
    color: $gray;
    font-size: 12px;
    line-height: 21px;
    text-align: center;
    margin-bottom: 60px;
    display: block;

    &.clickable {
      cursor: pointer;
    }
  }
}
