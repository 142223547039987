.l-ourservices {
  position: relative;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;

  @include bp(md) {
    padding-bottom: 100px;
    padding-top: 100px;
  }
  &::before {
    content: image("home/blue-dots.png");
    top: 350px;
    #{$right}: 0;
    position: absolute;
    z-index: 0;
  }
  
  &::after {
    content: image("home/blue-dots-lg.png");
    top: 150px;
    #{$left}: 0;
    position: absolute;
    z-index: 0;
  }
  
}

.ourservices {
  padding-top: 30px;
  max-width: 250px;
  margin: 0 auto 25px;

  @include bp(md) {
    margin-bottom: 60px;
  }

  &.is-success .ourservices-icon {
    background-color: rgba($color: $success, $alpha: .1);
  }

  &.is-info .ourservices-icon {
    background-color: rgba($color: $info, $alpha: .1);
  }

  &.is-warning .ourservices-icon {
    background-color: rgba($color: $warning, $alpha: .1);
  }

  &.is-danger .ourservices-icon {
    background-color: rgba($color: $danger, $alpha: .1);
  }

  // ===================
  &-title {
    color: $dark;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 15px;

    @include bp(md) {
      font-size: 36px;
    }
  }

  &-desc {
    color: $txt3;
    font-size: 14px;
  }

  &-icon {
    height: 150px;
    width: 150px;
    margin-bottom: 30px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    margin-bottom: 30px;

    .image {
      align-self: center;
      height: 70px;
      width: 70px;
    }
  }

  &-cardTitle {
    color: $dark;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
    line-height: 36px;
  }

  &-cardDesc {
    color: rgba($color: $dark, $alpha: .5);
    font-size: 14px;
    line-height: 25px;
  }

  &-moreBtn {
    padding: 15px 50px;
    color: $dark;
    border: 1px solid $dark;
    font-size: 16px;
    font-weight: bold;
    border-radius: 15px;
  }
}
