.l-newEvent {

  @include bp(md) {
    display: flex;
    align-items: stretch;

  }

  &-sideNav {
    background-color: $section-bg;
    padding: 20px 15px;

    @include bp(md) {
      padding: 30px 45px;
      flex: 0 0 350px;

    }
  }

  &-content {
    padding: 20px 15px;

    @include bp(md) {
      padding: 30px;
      flex: 0 0 calc(100% - 350px);

    }
  }
}

.newEvent {
  &-desc {
    color: $gray;
    font-size: 14px;
    line-height: 25px;
    margin-bottom: 30px;
  }

  &-actionsWrapper {
    border-top: 1px solid #CCC;
    padding-top: 32px;
  }
  &-creation {
    .vr {
      width:1px;
      height: 100%;
      background-color: $gray;
    }
    .form-control {
      &::placeholder {
        // color: $dark;
        // font-weight: 700;
      }
    }
  }
}
