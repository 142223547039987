.eventCard {
  position: relative;
  border: 1px solid $section-bg;
  border-radius: 8px;
  margin-bottom: 30px;
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(15, 40, 55, 0.1);
  @include trans(box-shadow);
  background-color: #ffff;

  &-cover {
    @include br-t(8px);
    overflow: hidden;
  }

  &-body {
    height: 200px;
    padding: 23px;
  }

  &-cardTitle {
    font-size: 16px;
    color: $dark;
    font-weight: bold;
    line-height: 28px;
    margin-bottom: 10px;
  }

  &-cardDate {
    font-size: 12px;
    color: $txt3;
    margin-bottom: 18px;
  }

  &-cardText {
    font-size: 14px;
    color: rgba($color: $dark, $alpha: 0.7);
    opacity: 1;
    height: 45px;
    visibility: visible;
    margin-bottom: 15px;
    overflow: hidden;
    @include trans();
  }

  &-btns {
    margin-inline-start: -5px;
  }

  &-location,
  &-type {
    border-radius: 30px;
    font-size: 12px;
    line-height: 24px;
    padding: 6px 14px;
    display: inline-block;
  }

  &-location {
    background-color: rgba($color: $primary, $alpha: 0.1);
    color: $primary;
    margin: 5px;
  }

  &-type {
    color: rgba($color: $dark, $alpha: 0.5);
    background-color: rgba($color: $gray, $alpha: 0.1);
  }

  //   &-location {
  //     padding: 10px 20px;
  //     color: $primary;
  //     border-radius: 25px;
  //     background-color: rgba($color: $primary, $alpha: 0.2);
  //     margin-#{$right}: 15px;

  //     &:hover {
  //       color: $txt3;
  //     }
  //   }

  //   &-type {
  //     padding: 10px 20px;
  //     color: $txt3;
  //     border-radius: 25px;
  //     background-color: rgba($color: $txt3, $alpha: 0.2);
  //   }

  &-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: $primary;
    height: 0;
    opacity: 0;
    visibility: hidden;
    @include trans();
    text-align: center;
    @include br-b(8px);
  }

  &-reserveBtn {
    color: $light;
    padding: 12px 0;
    font-size: 14px;
    width: 100%;

    &:hover,
    &:focus {
      color: darken($color: $light, $amount: 0.1);
    }
  }

  // hover  state
  &:hover {
    box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.2);
    border-color: $primary;

    .eventCard-cover .image {
      transform: scale(1.1);
    }

    .eventCard-cardDate {
      margin-bottom: 23px;
    }

    .eventCard-cardText {
      opacity: 0;
      height: 0;
      visibility: hidden;
    }

    .eventCard-footer {
      opacity: 1;
      visibility: visible;
      height: 52px;
    }
  }
}
