.l-controlPanal {
  min-height: calc(100vh - 172px);

  &-header {
    margin-bottom: 20px;
  }

  &-topRibbon {
    background-color: #fff;
    height: auto;
    border-bottom: 1px solid $section-bg;
    box-shadow: 0 4px 8px 0 rgba(15, 40, 55, 0.1);
  }

  &-wrapper {
    display: flex;
    position: relative;
    min-height: calc(100vh - 336px);
  }

  &-sideNav {
    position: absolute;
    top: 0;
    #{$left}: -300px;
    z-index: 10;
    width: 300px;
    flex: 0 0 300px;

    &.is-active {
      #{$left}: 0;
    }
    @include bp(lg) {
      position: relative;
      #{$left}: initial;
    }
  }

  &-togglerBtn {
    position: absolute;
    #{$left}: 100%;
    top: 0;
    background: #fff;
    border-radius: 0;
    padding: 10px 20px;
    box-shadow: -4px 5px 5px 0px rgba($color: #000000, $alpha: 0.3);

    @include bp(lg) {
      display: none;
    }

    &:focus {
      box-shadow: none;
    }
  }

  &-body {
    overflow: auto;
    background-color: $section-bg;
    padding: 30px 15px;
    flex: 100%;

    @include bp(lg) {
      padding: 45px 40px 30px;
      width: calc(100% - 300px);
      flex: 0 0 calc(100% - 300px);
    }

    // @include bp(md) {
    //   padding: 20px 40px 30px;
    //   width: calc(100% - 300px);
    //   flex: 0 0 calc(100% - 300px);
    // }
  }
}
// .l-controlPanal-body {
//   padding: 20px 40px 30px;
//   width: calc(100% - 300px);
//   flex: 0 0 calc(100% - 300px);
// }