.l-review {
    padding: 10px;
}

// .l-controlPanal-body {
//     padding: 0 25px;

//     @media screen and (max-width: 1024px) {
//         padding: 0 !important;
//     }
// }

.container {
    padding: 35px 0;

    @media screen and (max-width: 1024px) {
        padding: 24px 8px;
    }
}

.review {
    &-invoice {
        @extend .shadow;

        .padding-12 {
            padding-right: 12px !important;
            padding-left: 12px !important;
        }

        .padding-0 {
            padding-right: 0px !important;
            padding-left: 0px !important;
        }

        &-header {
            align-items: center;
            border-bottom: 1px dashed #C7C9D9;
            margin: 0 !important;
            padding: 25px 15px;

            &__status {
                align-items: center;

                &-label {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 21px;
                    color: #718096;

                    @media screen and (max-width: 1024px) {
                        padding: 0 24px !important
                    }
                }

                &-container {
                    padding: 0 !important;

                    @media screen and (max-width: 1024px) {
                        text-align: end;
                    }
                }

                &-box {
                    border-radius: 4px;
                    opacity: 0px;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 16px;
                    max-width: 100px;
                    min-width: 100px;
                    padding: 8px 24px;
                    text-align: center;
                    align-content: center;
                    color: white;

                    @media screen and (max-width: 1024px) {
                        padding: 6px 12px;
                        font-size: 12px;
                    }
                }

                @media screen and (max-width: 1024px) {
                    // justify-content: space-between;
                    margin-bottom: 14px;
                    align-items: center;
                }
            }

            &__actions {
                justify-content: end;

                &-btn {
                    padding-right: 12px !important;
                    padding-left: 12px !important;
                    outline: none !important;

                    .icon {
                        width: 20px;
                    }
                }

                &-label {
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 20px;
                    color: $primary;
                    margin: 0 5px;

                    .ltr & {
                        font-size: 14px;
                    }
                }

                @media screen and (max-width: 1024px) {
                    font-size: 14px;
                    justify-content: space-between;

                    img {
                        width: 18px;
                    }

                    &-btn {
                        text-align: start;
                        justify-content: normal !important;
                        padding: 5px 20px !important;

                        .icon {
                            width: 18px;
                        }
                    }

                    &-label {
                        font-size: 14px;
                    }
                }
            }

            @media screen and (max-width: 1024px) {
                padding: 22px 0px 12px 0 !important;
            }
        }

        &-info {
            padding: 30px;
            border-bottom: 1px dashed #C7C9D9;

            &__top {
                margin-bottom: 30px;
            }

            &__item {
                &-label {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 21px;
                    color: #718096;
                    margin-bottom: 4px;
                }

                &-value {
                    font-size: 15px;
                    font-weight: 700;
                    line-height: 27px;
                    color: #28293D;
                }
            }

            @media screen and (max-width: 1024px) {
                padding: 20px;

                &__top {
                    margin-bottom: 0px;
                }

                &__item {
                    &-value {
                        margin-bottom: 14px;
                    }
                }
            }
        }

        &-tickets {
            padding: 16px 30px;

            &__ticket {
                margin-bottom: 30px;
                opacity: 0px;
                border: 0.5px solid #C7C9D9;
                border-radius: 8px;

                &-part1 {
                    padding: 16px;

                    .ltr & {
                        border-right: 1px dashed #C7C9D9;
                    }

                    .rtl & {
                        border-left: 1px dashed #C7C9D9;
                    }

                    &-content {
                        align-items: center;

                        &-qr {
                            display: flex;
                            flex-direction: column;
                            align-items: center;

                            @include bp(md) {
                                margin: 0;
                            }

                            .qrcodFrame {
                                width: 100px;
                                height: 100px;
                                margin: 0 20px;

                                .container_ {
                                    position: relative;
                                    text-align: center;
                                    border: 1px solid #EEEFF2;
                                    border-radius: 6px;
                                }

                                .tag {
                                    position: absolute;
                                    z-index: 1000;
                                    background-color: #C7C9D9;
                                    padding: 4px;
                                    min-width: 98px;
                                    top: 38px;

                                    &-text {
                                        font-size: 14px;
                                        color: #28293D;
                                        text-align: center;
                                    }
                                }

                                img {
                                    width: 100%;
                                }
                            }

                            &-number {
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 24px;
                                color: #727385;
                                margin-top: 6px;
                            }
                        }

                        &-info {
                            align-content: center;
                            padding-top: 12px;

                            &-name {
                                font-size: 22px;
                                font-weight: 700;
                                line-height: 32px;
                                color: #28293D;
                            }

                            &-price {
                                font-size: 22px;
                                font-weight: 400;
                                line-height: 32px;
                                color: #555770;
                            }

                            &-date-details {
                                color: #727385;
                                margin-top: 18px;

                                &-with-details {
                                    font-size: 14px;
                                    font-weight: 400;
                                    line-height: 24px;
                                }

                                &-day,
                                &-time {
                                    display: flex;
                                    font-size: 16px;
                                    font-weight: 400;
                                    line-height: 28px;

                                    .ltr & {
                                        font-size: 14px;
                                    }

                                    span {
                                        margin-top: 6px;

                                        .ltr & {
                                            margin-top: 0px;
                                        }
                                    }

                                    .icon {
                                        width: 20px;
                                    }
                                }
                            }
                        }
                    }
                }

                &-part2 {
                    align-content: center;

                    &-content {
                        &-approval {
                            .btn {
                                margin: 18px auto;
                                color: white;
                                border-radius: 100px;
                                display: flex;
                                padding: 8px 12px;
                                width: 152px;

                                .ltr & {
                                    padding: 8px;
                                    width: 180px;
                                }

                                .icon {
                                    margin: 0 6px;
                                }
                            }

                            .bg__success {
                                background-color: $primary;
                            }

                            .bg__danger {
                                background-color: #E5263D;
                            }
                        }

                        &-actions {
                            display: flex;
                            flex-direction: column;
                            align-items: start;
                            padding-inline-start: 22px;

                            .btn {
                                align-items: center;
                                color: $primary;
                                text-align: right;
                                padding: 8px 0px;
                                outline: none !important;
                                box-shadow: none !important;

                                .login-icon {
                                    .ltr & {
                                        transform: rotate(180deg);
                                    }
                                }
                            }

                            .icon {
                                width: 20px;
                                margin: 0 4px;
                            }
                        }
                    }

                    .btn {
                        font-size: 16px;
                    }
                }
            }

            @media screen and (max-width: 1024px) {
                padding: 16px 34px;

                &__ticket {
                    margin-bottom: 18px;

                    &-part1 {
                        padding: 12px 10px;
                        border-right: none !important;
                        border-left: none !important;
                        border-bottom: 1px dashed #C7C9D9;

                        &-content {
                            align-items: center;
                            margin-left: 0 !important;
                            margin-right: 0 !important;

                            &-qr {
                                margin: 0 auto 0;
                                padding-left: 0 !important;
                                padding-right: 0 !important;

                                .qrcodFrame {
                                    margin: 0;
                                    width: auto;
                                    height: auto;

                                    .tag {
                                        position: absolute;
                                        z-index: 1000;
                                        background-color: #C7C9D9;
                                        padding: 4px;
                                        min-width: 77px;
                                        top: 30px;

                                        &-text {
                                            font-size: 14px;
                                            color: #28293D;
                                            text-align: center;
                                        }
                                    }
                                }

                                &-number {
                                    font-size: 11px;
                                    margin-top: 4px;
                                }
                            }

                            &-info {
                                &-top {
                                    justify-content: space-between;
                                }

                                align-content: center;
                                padding-top: 12px;

                                &-name {
                                    font-size: 14px;
                                }

                                &-price {
                                    padding-left: 8px !important;
                                    font-size: 14px;
                                    text-align-last: end;

                                    .rtl & {
                                        padding-right: 8px !important;
                                    }
                                }

                                &-date-details {
                                    margin-top: 6px;

                                    &-with-details {
                                        font-size: 12px;
                                    }

                                    &-day,
                                    &-time {
                                        font-size: 12px;

                                        span {
                                            margin-top: 0px;
                                        }

                                        .icon {
                                            width: 16px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &-part2 {
                        padding-left: 6px !important;
                        padding-right: 6px !important;
                        align-content: center;

                        &-content {
                            &-approval {
                                display: flex;
                                justify-content: space-between;
                                padding: 0 12px;

                                .ltr & {
                                    padding: 0;
                                }

                                .btn {
                                    width: 120px;

                                    .ltr & {
                                        width: 138px
                                    }

                                    .icon {
                                        margin: 0 4px;
                                    }
                                }
                            }

                            &-actions {
                                padding-inline-start: 0px;
                                display: flex;
                                justify-content: space-around;
                                flex-direction: row;

                                .icon {
                                    width: 14px;
                                    margin: 0 2px;
                                }
                            }
                        }

                        .btn {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}