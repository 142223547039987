.l-homeContact {
  position: relative;
  padding-top: 100px;
  padding-bottom: 30px;
  background-color: $section-bg;

  &::before {
    content: image("home/contact-left-icon.png");
    bottom: 130px;
    #{$right}: 0;
    position: absolute;
  }

  // &:after {
  //   content: "";
  //   bottom: 0;
  //   #{$left}: 0;
  //   position: absolute;
  //   background: image("home/contact-right-icon.png") no-repeat top/contain;
  //   width: 150px;
  //   height: 150px;

  //   @include bp(sm) {
  //     width: 350px;
  //     height: 350px;
  //   }

  //   @include bp(ms) {
  //     width: 600px;
  //     height: 600px;
  //   }

  // }

}

.homeContact {

   &-title {
     color: $dark;
     font-size: 32px;
     font-weight: bold;
   }

   &-btn {
     padding: 15px 40px;
     font-weight: bold;

     @include bp(md) {
       padding: 15px 200px;
     }
   }

   &-icon,
   &-info {
     color: rgba($color: $dark, $alpha: .5);
     font-size: 16px;
     //font-weight: bold;
     line-height: 28px;
     font-size: 18px;
     margin-#{$right}: 15px;
   }


   &-icon {
     vertical-align: middle;
     margin-#{$right}: 15px;
   }

   &-info {
     display: inline-block;
     margin-bottom: 0;
   }

 }
