.emails {

  &-report {
    &__container {
      margin-bottom: 30px;

      @media screen and (max-width: 1024px) {
        margin-bottom: 18px;
      }
    }
  }

  &-header {
    display: flex;
    justify-content: space-between;
    padding: 0 8px;
    margin-bottom: 18px;

    &__title {
      font-size: 32px;
      font-weight: 700;

      @media screen and (max-width: 1024px) {
        font-size: 28px;
      }
    }

    &__actions {
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      font-weight: 700;


      &-primary-btn {
        background-color: $primary;
        color: $light;
        display: inline-flex;
        user-select: none;
        transition: color .15s ease-in-out;
        padding: 10px 16px;
        border-radius: 100px;
        align-items: center;
        font-size: 18px;
        font-weight: 700;

        &:focus {
          box-shadow: none;
          outline: 0;
        }

        @media screen and (max-width: 1024px) {
          padding: 6px 16px;
        }
      }

      img {

        @media screen and (max-width: 1024px) {
          width: 22px;
        }
      }


      @media screen and (max-width: 1024px) {
        margin-top: 6px;
        font-size: 12px;
        display: flex;
        justify-content: space-between;
      }
    }

    @media screen and (max-width: 1024px) {
      display: block;
    }
  }

  &-filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;

    .ng-select .ng-select-container .ng-arrow-wrapper {
      height: 24px;
      width: 25px;
    }

    @media (max-width: 1024px) {
      display: contents;
    }

    &__sort {
      border: none;

      &-text {
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        color: #555770;

        @media (max-width: 1024px) {
          padding: 0 10px;
        }
      }

      .unclearable {
        display: flex;
        align-items: center;

        .ng-select .ng-clear-wrapper {
          display: none;
        }

        @media (max-width: 1024px) {
          justify-content: space-between;
        }
      }

      .ng-value-container {
        justify-content: center !important;
        font-weight: 700 !important;
      }

      .ng-select.ng-select-single .ng-select-container,
      .ng-select.ng-select-multiple .ng-select-container {
        padding: 12px;
        height: 21px;
        border: none;
        border-radius: 0;
        transition: none;
        min-width: 120px;
        background-color: transparent;

        &:hover {
          box-shadow: none !important;
        }
      }
    }

    &__actions {
      display: flex;

      &-search {
        .form-control {

          @media (min-width: 1024px) {
            width: 300px;
          }

          background-color: transparent;
          border-radius: 100px;
        }
      }

      &-select {
        .ng-select .ng-select-container {
          background-color: transparent;
          border-radius: 100px !important;
        }

      }

      @media (max-width: 1024px) {
        display: contents;
      }

    }

  }

  &-table {
    td {

      font-size: 14px;
      font-weight: normal !important;
      line-height: 20px;
      color: #555770;
      vertical-align: middle;

      p {
        margin-bottom: 0;
      }
    }

    .status-box {
      border-radius: 4px;
      opacity: 0px;
      font-size: 12px;
      font-weight: 700 !important;
      line-height: 14px;
      width: 72px;
      padding: 6px 0;
      text-align: center;
      align-content: center;
      color: white;
    }

  }

  &__add-mail {
    &__title {
      display: flex;
      margin-bottom: 20px;

      img {
        .ltr & {
          transform: rotate(180deg);
        }
      }

      &-text {
        font-size: 32px;
        font-weight: 700;
        line-height: 48px;
        color: #0F1F2D;
        margin: 0 4px;

        @media screen and (max-width: 1024px) {
          font-size: 24px;
          line-height: 36px;
        }
      }
    }

    &__card {
      background-color: white;
      padding: 48px;
      gap: 16px;
      border-radius: 16px;

      @media screen and (max-width: 1024px) {
        padding: 18px;
      }

      .form-label,
      .form-group label,
      .control-label {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        color: #28293D;
      }

      &-attachment {
        display: flex;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        color: #43C0B5;
        width: max-content;
        margin-top: 20px;

        @media screen and (max-width: 1024px) {
          margin-top: 10px;
          font-size: 14px;
        }
      }

      .add-btn {
        text-align: end;
        margin-top: 12px;

        button {
          @media screen and (max-width: 1024px) {
            width: -webkit-fill-available;
            justify-content: center;
            margin-top: 30px;
          }
        }

        img {
          .ltr & {
            transform: rotate(180deg);
          }
        }

      }
    }

  }

  &__details {
    &-content {
      margin-top: 12px;
    }
  }

}