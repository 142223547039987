.homeEvents {
  &-head {
    margin: 50px 0;
  }

  &-cover {
    max-width: 100%;
  }

  &-sectitle {
    font-weight: bold;
    margin-bottom: 0;
    text-align: center;
    font-size: 24px;

    @include bp(sm) {
      font-size: 36px;
      text-align: inherit;
      margin-#{$right}: 50px;
      margin-top: 10px;
    }
  }

  &-select {
    border: 1px solid rgba($color: $dark, $alpha: 0.2);
    padding: 15px 20px;
    border-radius: 15px;
    color: $primary;
    margin-#{$right}: 20px;
    font-size: 20px;
  }

  &-location {
    border: none;
    color: $primary;
    font-size: 14px;
    font-weight: bold;
  }

  &-moreBtn {
    padding: 15px 50px;
    color: $dark;
    border: 1px solid $dark;
    font-size: 16px;
    font-weight: bold;
    border-radius: 15px;
  }

  &-content {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  &-title {
    font-size: 32px;
    font-weight: bold;
    line-height: 58px;
    color: $dark;
  }

  &-desc {
    font-size: 16px;
    line-height: 28px;
    color: $txt3;
  }
}
