.sponsorCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border: 1px solid rgba($color:$gray, $alpha: .5);
  padding: 25px 15px;
  margin-bottom: 10px;
  border-radius: 16px;

  background-color: #fff;

  &-data {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &-img {
      width: 64px;
      min-width: 64px;
      height: 64px;

      img {
        border-radius: 15px;
      }
    }

    &-name {
      margin-inline-start: 20px;
      margin-inline-end: 5px;
      margin-bottom: 15px;
      width: 100%;

      p {
        margin-bottom: 0.5rem;
      }

      span {
        color: $gray;
      }
    }
  }

  &-actions {
    display: flex;
    button {
      padding: 0.8rem 1.2rem;
      box-shadow: inset 2px 2px 0 0px #f9efef;
      margin-inline-start: 5px;
      margin-inline-end: 5px;
      color: #fff;
    }
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.sponsorPlus {
  background-color: #fff;
  box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  padding: 15px 20px;
  margin-bottom: 30px;

  > form {
    display: flex;
    align-items: center;
    @media screen and (max-width: 991px) {
      flex-direction: column;
    }
  }

  &-sponsorImage {
    margin-inline-end: 20px;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 991px) {
      margin: 0 0 20px;
    }
  }

  &-sponsorName {
    display: flex;
    flex: 1;
    margin-inline-end: 20px;

    app-evento-input {
      width: 100%;
      + app-evento-input {
        margin-inline-start: 10px;
        @media screen and (max-width: 479px) {
          margin: 10px 0 0;
        }
      }
    }

    @media screen and (max-width: 991px) {
      margin: 0 0 20px;
    }
    @media screen and (max-width: 479px) {
      flex-direction: column;
    }
  }

  &-clientName {
    display: block;
    flex: 1;
    margin-inline-end: 20px;

    app-evento-input {
      width: 100%;
      + app-evento-input {
        margin-inline-start: 10px;
        @media screen and (max-width: 479px) {
          margin: 10px 0 0;
        }
      }
    }

    @media screen and (max-width: 991px) {
      margin: 0 0 20px;
    }
    @media screen and (max-width: 479px) {
      flex-direction: column;
    }
  }

  &-sponsorActions {
    display: flex;

    .btn + .btn {
      margin-inline-start: 10px;
    }

    @media screen and (max-width: 991px) {
      justify-content: center;
    }


  }


}
