
.services {

  &-title {
    color: $dark;
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  &-desc {
    color: $txt3;
    font-size: 14px;
  }

  &-icon {
    height: 70px;
    width: 70px;
    margin-top: 50px;
    margin: auto;
  }

  &-cardTitle {
    color: $dark;
    font-size: 20px;
    font-weight: bold;
    margin-top:5px;
    margin-bottom:15px ;
  }
  &-cardDesc {
    color: $txt3;
    font-size: 14px;
    padding: 5px 55px 30px;
  }
  &-moreBtn {
    padding: 15px 50px;
    color: $dark;
    border: 1px solid $dark;
    font-size: 16px;
    font-weight: bold;
    border-radius: 15px;
  }
}
