// General Class

.generla_compenent {

  background-color: $section-bg;
  padding: 30px 15px;

  @include bp(md) {
    padding: 30px 45px 45px;
  }

 

  // Ticket Check Report
  // .ticketCheckReport {
  //   border-radius: 16px;
  //   min-height: 371px;
  //   background-color: #ffffff;
  //   padding: 25px;
  //   margin-bottom: 30px;

  //   &-header {
  //     display: flex;
  //     justify-content: space-between;
  //     align-items: center;

  //     &-title {
  //       font-size: 24px;
  //       font-weight: bold;
  //       color: $dark;
  //     }

  //     &-dropdown {
  //       display: flex;
  //       align-items: center;

  //     }
  //   }
  // }

  // Ticket inspection report per minute
  // .ticketReportPerMinutet {
  //   border-radius: 16px;
  //   min-height: 371px;
  //   background-color: #ffffff;
  //   padding: 25px;
  //   margin-bottom: 30px;
  //   // margin-#{right}: 2%;
  //   // margin-#{left}: 2%;

  //   &-header {
  //     display: flex;
  //     justify-content: space-between;
  //     align-items: center;

  //     &-title {
  //       font-size: 24px;
  //       font-weight: bold;
  //       color: $dark;
  //     }

  //     &-dropdown {
  //       display: flex;
  //       align-items: center;

  //     }
  //   }
  // }



 

  // matches Calendar



}
