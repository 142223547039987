.myTicket {
  border-radius: 8px;
  background-color: #f4f5f7;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  text-align: center;

  @include bp(md) {
    text-align: $left;
    flex-direction: row;
  }

  &-wrapper {
    margin-top: 5%;
  }

  &-part1 {
    padding: 12px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;

    @include bp(md) {
      flex-direction: row;
      flex: 0 0 65%;
      max-width: 65%;
    }
  }

  &-qr {
    width: 70px;
    height: 100px;
    margin: 0 auto 30px;
    display: flex;
    align-items: center;

    @include bp(md) {
      margin: 0;
    }

    .qrcodFrame {
      width: 50px;
      height: 50px;
      // overflow: hidden;
      //margin: 0 10px;

      @media (max-width: 991px) {
        margin: 0;
      }

      img {
        width: 100%;
      }
    }

    @media (max-width: 991px) {
      margin: 0 auto 0;
    }
  }

  &-user,
  &-number {
    margin-top: 20px;
    min-width: 0;
    padding-inline-end: 0;

    @media (max-width: 991px) {
      margin-top: 15px;
    }

    &-name {
      color: #071927;
      font-size: 14px;
      margin-bottom: 4px;
    }

    &-phone,
    &-email {
      color: rgba($color: #071927, $alpha: 0.5);
      font-size: 14px;
      line-height: 25px;
      margin-bottom: 6px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      direction: ltr;
      text-align: center;

      @include bp(md) {
        text-align: left;

        [dir="rtl"] & {
          text-align: right;
        }
      }
    }
  }

  // =============
  &-partSeparator {
    display: none;

    @include bp(md) {
      display: block;
    }

    position: relative;
    flex: 0 0 42px;
    max-width: 42px;
    padding-top: 21px;
    padding-bottom: 21px;

    &-line {
      width: 0;
      height: 100%;
      border-left: 2px dashed rgba($color: #a1a1a1, $alpha: 0.5);
      margin: 0 auto;
    }

    &:after,
    &::before {
      content: "";
      position: absolute;
      width: 42px;
      height: 42px;
      border-radius: 50%;
      background-color: #fff;
      left: 0;
      z-index: 10;
    }

    &::before {
      top: -21px;
    }

    &:after {
      bottom: -21px;
    }
  }

  &-part2 {
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .myTicket-name {
      color: #071927;
      font-size: 14px;
      line-height: 25px;
      margin-bottom: 5px;
    }

    .myTicket-price {
      color: #a1a1a1;
      font-size: 14px;
      line-height: 25px;
      margin-bottom: 12px;
    }
  }

  &-downloadBtn {
    padding: 0;
    color: $primary;
    font-size: 14px;
    line-height: 21px;

    &:hover,
    &:focus {
      color: $primary;
    }

    i {
      margin-inline-end: 5px;
    }
  }

  & + .myTicket {
    margin-top: 30px;
  }
}

.tickets {
  &-types {
    margin-bottom: 1.9rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 30px;

    .typeBox {
      height: 120px;
      padding: 30px 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background: #fff;
      border-radius: 16px;
      @extend .shadow;

      &-icon {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }

      &-sum {
        margin-#{$left}: 15px;

        .text {
          color: $gray;
          margin-bottom: 0.2rem;
        }

        .number {
          font-weight: bold;
        }
      }

      &:first-child .typeBox {
        &-icon {
          background: rgba($primary, 0.2);
          color: $primary;
        }

        &-sum .number {
          color: $primary;
        }
      }

      &:nth-child(2) .typeBox {
        &-icon {
          background: rgba($success, 0.2);
          color: $success;
        }

        &-sum .number {
          color: $success;
        }
      }

      &:nth-child(3) .typeBox {
        &-icon {
          background: rgba($info, 0.2);
          color: $info;
        }

        &-sum .number {
          color: $info;
        }
      }

      &:nth-child(4),
      &:nth-child(6),
      &:nth-child(7) {
        .typeBox {
          &-icon {
            background: rgba($danger, 0.2);
            color: $danger;
          }

          &-sum .number {
            color: $danger;
          }
        }
      }

      &:nth-child(5) .typeBox {
        &-icon {
          background: rgba($warning, 0.2);
          color: $warning;
        }

        &-sum .number {
          color: $warning;
        }
      }
    }
  }
}

.container_ {
  position: relative;
  text-align: center;
}

.tag {
  p {
    margin-bottom: 0;
  }

  position: absolute;
  z-index: 1000;
  background-color: #eeee;
  padding: 5px;
  color: #000;
  // font-weight: bold;
  min-width: 100px;
  // max-width: 100px;
  top: 30px;

  @media (max-width: 991px) {
    min-width: 100px;
  }
}

.approval-actions {
  .btn {
    color: white;
    margin: 10px;
    padding: 10px 35px;
  }
}
