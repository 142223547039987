ngx-dropzone {
  border: 1px dashed #a1a1a1 !important;
  color: inherit;
  height: 250px !important;
  transition: 0.3s;

  ngx-dropzone-label {
    display: flex;
    align-items: center;
    flex-direction: column;
    i {
      width: 100px;
      height: 100px;
      color: $primary;
      border: 1px solid $gray;
      border-radius: 50%;
      background: rgba(61, 190, 180, 0.2);
      box-shadow: inset -5px 0 0 white;
      font-size: 30px;
      display: flex !important;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;

      .rtl & {
        box-shadow: inset 5px 0 0 white;
      }
    }

    .browse {
      font-weight: 700;
      color: $primary;
      margin: 0;
    }
  }

  ngx-dropzone-image-preview {
    display: block !important;
    text-align: center;

    ngx-dropzone-label {
      display: none !important;
    }
  }

  &.ngx-dz-hovered {
    border-color: $primary !important;
    border-width: 2px !important;
    box-shadow: inset 0 0 5px rgba($color: #000000, $alpha: 0.1);
  }

  &:hover {
    border-color: $primary !important;
  }

  &.single-img {
    height: 140px !important;
    ngx-dropzone-image-preview {
      margin: 0 auto !important;
    }
  }
}
