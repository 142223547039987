.l-review {
  padding: 10px;
}

.review {
  &-personalDetails {
    margin-top: 30px;
  }

  &-label {
    font-size: 14px;
    color: rgba($dark, 0.4);
    text-align: #{$left};
  }

  &-details {
    font-size: 14px;
    color: $dark;
    text-align: #{$left};

    @include bp(md) {
      padding-top: 15px;
    }
  }

  &-visitor {
    // border: 1px solid rgba($dark, 0.2);
    border-radius: 16px;
    padding: 30px;
    margin: 50px auto;
    @extend .shadow;

    @include bp(md) {
      .horizontalTicketCard-qrcodDetails {
        max-width: 280px;
        flex: 0 0 280px;
      }
    }
  }

  &-coupon {
    border-radius: 16px;
    padding: 30px;
    margin: 50px auto;
    @extend .shadow;

    .text-left {
      display: block;
    }

    .input-container {
      &-check {
        #{$right}: 20px;
        top: 15px;
        font-weight: 700;
      }
    }
  }

  &-check {
    .image {
      width: 100%;
    }
  }

  &-title {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: $dark;
    margin-bottom: 30px;
  }

  &-ticket {
    background: rgba($dark, 0.1);
    border-radius: 8px;
    overflow: auto;
    padding: 25px 15px;
  }

  &-nameVisitor {
    @include bp(md) {
      float: #{$left};
    }
  }

  &-ticketVisitor {
    @include bp(md) {
      float: #{$right};
    }
  }

  &-dashed {
    display: none;

    @include bp(md) {
      display: block;
    }

    &::before {
      content: "";
      width: 45px;
      height: 35px;
      border-radius: 50%;
      display: block;
      background: $light;
      position: relative;
      bottom: 30px;
      #{$left}: 24px;
    }

    &::after {
      content: "";
      width: 45px;
      height: 35px;
      border-radius: 50%;
      display: block;
      background: $light;
      position: relative;
      top: 57px;
      #{$left}: 24px;
    }
  }

  &-payment {
    // border: 1px solid rgba($dark, 0.2);
    border-radius: 16px;
    padding: 30px 0 0 0;
    margin: 50px auto;
    @extend .shadow;
  }

  &-paymentDetails {
    text-align: center;
  }

  &-paymentlabel {
    font-size: 14px;
    color: rgba($dark, 0.4);
  }

  &-paymentNum {
    font-size: 14px;
    color: $dark;
    padding-top: 15px;
  }

  &-total {
    padding: 20px;
    width: 100%;
    border-radius: 0 0 8px 8px;
    background: rgba($danger, 0.1);
  }

  &-all {
    font-size: 20px;
    font-weight: bold;
  }

  &-sum {
    font-size: 20px;
    color: $danger;
  }
}
