// ===================[ Elbaz Co - FE Engineering Team - 2020 ]
/* Ahmed.elbazz.2010@gmail.com*/
/* linkedin.com/in/elbazz */
// ### =======================  [ Configrations ] ========================== ### //
// $mainSiteLang : ar; // active the next variable if you want arabic direction
// Defenition:: Functions, Variables, Mixins
@import "core/initial"; // ::=> Set this partail to defined new style
// ### ===================================================================== ### //
// ### =============================  [ Core ] ============================= ### //
@import "core/base"; // ::=> Dont repeat this partail
@import "core/helpers"; // ::=> Dont repeat this partail
//ss
// ### ===================================================================== ### //
// ### ============================  [ Vendors ] =========================== ### //
// [^\$](left|right|rtl|ltr)[^\}]                     // Partail Author  - MM-DD-YY
@import "vendors/dynamicBootstrap-4.3.1/bootstrap";
@import "settings/bootstrapOverride";
$themeColor: $primary;
@import "vendors/fontawesome-free-5.15.1/base";

@import "vendors/swiper-6.5.5/base";

// ### ===================================================================== ### //
// ### ============================  [ Layouts ] =========================== ### //
@import "layouts/common"; // Ahmed Elbaz  - 7-11-2018
@import "layouts/auth"; // Ahmed Elbaz  - 7-11-2018
@import "layouts/staticModal"; // Ahmed Elbaz  - 7-11-2018
@import "layouts/homepage"; // Ahmed Elbaz  - 7-11-2018
@import "layouts/services"; // Ahmed Elbaz  - 7-11-2018
@import "layouts/aboutus"; // Ahmed Elbaz  - 7-11-2018
@import "layouts/gray"; // Ahmed Elbaz  - 7-11-2018
@import "layouts/control-panal"; // Ahmed Elbaz  - 7-11-2018

// ### ===================================================================== ### //
// ### ============================  [ Modules ] =========================== ### //
// ###  ============  [ Common ]
@import "modules/base"; // Mandatory Components Ahmed Elbaz  - 05-18-2018
@import "modulesLanding/base"; // Mandatory Components Ahmed Elbaz  - 05-18-2018
@import "modulesDashboard/base"; // Mandatory Components Ahmed Elbaz  - 05-18-2018
@import "modules/toast";
@import "modules/datepicker";
@import "modules/ng-select-override";
@import "modules/file-uploader";
@import "modules/myticket";
// @import "modules/myticket";
