.startegies {
  margin-bottom: 30px;

  &-box {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    background: $light;
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 10px;
    @extend .shadow;
    &-img {
      flex: 0 0 65px;
      height: 76px;

      .image {
        width: 100%;
        border-radius: 8px;
      }
    }

    &-content {
      flex-grow: 1;
      margin-top: 8px;
      margin-#{$left}: 0px;

      @include bp(sm) {
        margin-top: 0px;
        margin-#{$left}: 11px;

      }

      .contentTitle {
        margin-bottom: 5px;
      }

      .info {
        margin-bottom: 7px;
        color: rgba($gray, 0.50)
      }
    }
  }

  &-line {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }

  progress {
    border: none;
    height: 15px;
    flex-grow: 1;
  }

  progress::-moz-progress-bar {
    background: rgba($gray, 0.50);
    border-radius: 50px;
    margin: 0 12px;
  }

  progress::-webkit-progress-value {
    background: $primary;
    border-radius: 50px;
  }

  progress::-webkit-progress-bar {
    background: rgba($gray, 0.50);
    border-radius: 50px;
  }

  &-user {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-#{$right}: 11px;

    .user {
      width: 24px;
      height: 24px;
      border-radius: 50px;
      font-size: 11px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;

      &:first-child {
        background: #F89E00;
      }

      &:nth-child(2) {
        background-color: $info;
      }

      &:last-child {
        background-color: $section-bg;
      }
    }
  }

  .percentage {
    display: block;
    margin-#{$left}: 11px;
  }
}
