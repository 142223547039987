.invitations {

  &-report {
    &__container {
      margin-bottom: 30px;

      @media screen and (max-width: 1024px) {
        margin-bottom: 18px;
      }
    }
  }

  &-header {
    display: flex;
    justify-content: space-between;
    padding: 0 8px;
    margin-bottom: 18px;

    &__title {
      font-size: 32px;
      font-weight: 700;

      @media screen and (max-width: 1024px) {
        font-size: 28px;
      }
    }

    &__actions {
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      font-weight: 700;


      &-primary-btn {
        background-color: $primary;
        color: $light;
        display: inline-flex;
        user-select: none;
        transition: color .15s ease-in-out;
        padding: 10px 16px;
        border-radius: 100px;
        align-items: center;
        font-size: 18px;
        font-weight: 700;

        &:focus {
          box-shadow: none;
          outline: 0;
        }

        @media screen and (max-width: 1024px) {
          padding: 6px 16px;
        }
      }

      &-export-btn {
        cursor: pointer;
        margin-inline-start: 34px;

        span {
          margin: 0 10px;

          @media screen and (max-width: 1024px) {
            margin: 0 4px;
          }

        }

        @media screen and (max-width: 1024px) {
          margin-inline-start: 0;
        }
      }

      img {

        @media screen and (max-width: 1024px) {
          width: 22px;
        }
      }


      @media screen and (max-width: 1024px) {
        margin-top: 6px;
        font-size: 12px;
        display: flex;
        justify-content: space-between;
      }
    }

    @media screen and (max-width: 1024px) {
      display: block;
    }
  }

  &-filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;

    .ng-select .ng-select-container .ng-arrow-wrapper {
      height: 24px;
      width: 25px;
    }

    @media (max-width: 1024px) {
      display: contents;
    }

    &__sort {
      border: none;

      &-text {
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        color: #555770;

        @media (max-width: 1024px) {
          padding: 0 10px;
        }
      }

      .unclearable {
        display: flex;
        align-items: center;

        .ng-select .ng-clear-wrapper {
          display: none;
        }

        @media (max-width: 1024px) {
          justify-content: space-between;
        }
      }

      .ng-value-container {
        justify-content: center !important;
        font-weight: 700 !important;
      }

      .ng-select.ng-select-single .ng-select-container,
      .ng-select.ng-select-multiple .ng-select-container {
        padding: 12px;
        height: 21px;
        border: none;
        border-radius: 0;
        transition: none;
        min-width: 120px;
        background-color: transparent;

        &:hover {
          box-shadow: none !important;
        }
      }
    }

    &__actions {
      display: flex;

      &-search {
        .form-control {

          @media (min-width: 1024px) {
            width: 300px;
          }

          background-color: transparent;
          border-radius: 100px;
        }
      }

      &-select {
        .ng-select .ng-select-container {
          background-color: transparent;
          border-radius: 100px !important;
        }

      }

      @media (max-width: 1024px) {
        display: contents;
      }

    }

  }

  &-table {
    td {

      font-size: 14px;
      font-weight: normal !important;
      line-height: 20px;
      color: #555770;
      vertical-align: middle;

      p {
        margin-bottom: 0;
      }
    }

    .status-box {
      border-radius: 4px;
      opacity: 0px;
      font-size: 12px;
      font-weight: 700 !important;
      line-height: 14px;
      width: 72px;
      padding: 6px 0;
      text-align: center;
      align-content: center;
      color: white;
    }

  }

}

.design-wizard-items {
  display: flex;
  padding: 0;
  list-style: none;
  justify-content: space-between;
  width: 100%;
}

.design-wizard-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex: 1;
}

.design-wizard-item:not(:last-child) {
  margin-right: 20px;

  .rtl & {
    margin-left: 20px;
    margin-right: 0px;
  }
}

.design-wizard-step {
  width: 26px;
  height: 26px;
  background-color: gray;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 1;
}

.design-wizard-item.active .design-wizard-step {
  background-color: #43C0B5;
}

.design-wizard-line {
  width: 100%;
  height: 3px;
  background-color: gray;
  position: absolute;
  top: 50%;
  left: 130%;
  transform: translateX(-50%);
  z-index: 0;

  .rtl & {
    right: 50%;
    left: 0%;
  }
}

.line-active {
  background-color: #43C0B5;
}
