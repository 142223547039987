.filteration {
    position: fixed;
    top:0;
    #{$right}: 0;
    width:300px;
    height:100vh;
    background-color: $light;
    z-index: 20;
    padding: 30px 20px;
    box-shadow: 0 4px 18px 0 rgba(0,0,0,0.2);
    &-header {
        height: 25px;
        margin-bottom: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &-title {
        margin-bottom: 0;
    }
    &-closeBtn {
        padding: 0 8px;
        border: 1px solid currentColor;
        color: rgba($gray, 0.5);
        line-height: 23px;
        border-radius: 50%;
        font-size: 10px;
    }
    &-body {
        height: calc(100% - 130px);
        overflow-x: auto;
    }
    &-footer {
        height: 75px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .btn {
            border-radius: 16px;
        }
    }
    &-overlay {
        width:100vw;
        height:100vh;
        background-color: rgba(0,0,0,0.2);
        position: fixed;
        top:0;
        #{$right}: 0;
        z-index: 19;
    }
}