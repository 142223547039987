.horizontalTicketCard {
  position: relative;
  display: flex;
  border-radius: 8px;
  background-color: $section-bg;
  margin-bottom: 30px;
  flex-direction: column;

  @include bp(sm) {
    flex-direction: row;
  }

  &-seperator {
    max-width: 40px;
    flex: 0 0 40px;
    position: relative;
    overflow: hidden;
    display: none;

    @include bp(sm) {
      display: block;

    }

    &::after,
    &::before {
      content: "";
      position: absolute;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: $light;
      z-index: 2;
      #{$right}: 0;
    }

    &::after {
      top: -20px;
    }

    &::before {
      bottom: -20px;
    }

  }

  &-dashedLine {
    position: absolute;
    #{$right}: 50%;
    top: 25px;
    width: 1px;
    height: calc(100% - 50px);
    border: 1px dashed rgba($gray, .2);
    z-index: 1;
  }

  &-qrcodDetails {
    padding: 12px;
    display: flex;
    align-items: center;

    @include bp(sm) {
      max-width: 400px;
    }

    .qrcodFrame {
      flex: 0 0 100px;

      .image {
        max-width: 100%;

      }
    }

  }
  &-subtitle,
  &-ticketName {
    color: #6c757d;
    font-size: 14px;
  }
  &-ticketNameType {
    color: $dark;
  }
  &-personDetails {
    padding-top: 12px;
    margin-#{$left}: 15px;
  }

  &-name {
    font-size: 14px;
    line-height: 25px;
    color: $dark;
  }

  &-phone,
  &-email {
    color: rgba($dark, .5);
    font-size: 14px;
    line-height: 25px;
    margin-bottom: 0;
  }



  &-details {
    padding: 24px 20px;
    flex-grow: 1;

  }

  &-type {
    color: $dark;
    font-size: 14px;
    line-height: 25px;
    margin-bottom: 0;
  }

  &-price {
    color: $gray;
    font-size: 14px;
    line-height: 25px;
    margin-bottom: 12px;
  }

  &-downloadBtn {
    padding: 0;
    color: $primary;

  }
}
