.selectClub {
  &-card {
    text-align: center;
    border: 1px solid $primary;
    border-radius: 16px;
    margin-bottom: 20px;
    padding: 20px;
    transition: 0.5s;
    transition-property: transform, text-shadow;
    cursor: pointer;

    &:hover {
      border: 1px solid $primary;
      box-shadow: 0 0 11px $primary;
      transform: scale(1.1);
    }
  }
  &-logo {
    padding: 20px 0 10px;
    .image {
      width: 50%;
    }
  }
  &-name {
    padding-top: 20px;
    color: $secondary;
    font-size: 24px;
    font-weight: bold;
  }
}
